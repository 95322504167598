<template>
    <div class="grafico-relaciones-container">
        <div v-if="movil" class="leyenda-movil movil" :class="{'back-inactive': selected}">
            <div v-show="!selected" class="text-body text-leyenda" v-html="textos">
            </div>
        </div>
        <div v-if="movil" class="detalles-movil" v-show="selected" :class="{'back-inactive': !selected}">
            <v-layout row justify-space-between>
                <v-flex xs8>
                    <span v-if="!selected" class="title-detalles text-left">Detalles</span>
                    <span v-else class="title-detalles text-left">{{nodeData.label}}</span>
                </v-flex>
                <v-flex xs2>
                    <v-btn icon dark class="align-content-end" @click="closeDetail">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-flex>
            </v-layout>
            <div v-if="selected" class="text-detalles-movil">
                <div class="closeness">Cercanía: <b>{{nodeData.attributes["Closeness"].substring(0,5)}}</b>
                </div>
                <div class="betweness">Intermediación:
                    <b>{{nodeData.attributes["Betweenness"].substring(0,5)}}</b>
                </div>
                <div class="genero">Género: <b>{{nodeData.attributes["genero"]}}</b></div>
                <div class="featurings">
                    <b>Colaboraciones: </b>
                    <div class="artist-list">
                        <template v-for="(edge, i) in artistEdges">
                            <div v-if="(edge.label!=nodeData.label)" :key="i" @click="clickNode(edge.id)">{{edge.label}}</div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <v-layout row :class="{'full-row': movil}">
            <v-flex :xs8="!movil" class="cell-map" :class="{'cell-map-movil': movil}">
                <div id="sigma-container" ref="sigma_container"></div>
            </v-flex>
            <v-flex xs4 v-if="!movil" class="cell-data not-movil">
                <div class="leyenda" :class="{'back-inactive': selected}">
                    <div v-show="selected" class="title-leyenda" @click="closeDetail" style="cursor: pointer">Información</div>
                    <div v-show="!selected" class="text-body text-leyenda" v-html="textos">
                       </div>
                </div>
                <div class="detalles" :class="{'back-inactive': !selected}">
                    <v-layout row justify-space-between>
                        <v-flex xs8>
                            <span v-if="!selected" class="title-detalles text-left">Detalles</span>
                            <span v-else class="title-detalles text-left">{{nodeData.label}}</span>
                        </v-flex>
                        <v-flex xs2 v-if="selected">
                            <v-btn icon dark class="align-content-end" @click="closeDetail">
                                <v-icon>close</v-icon>
                            </v-btn>
                        </v-flex>
                    </v-layout>
                    <div v-if="selected" class="text-detalles">
                         <div class="closeness">Cercanía: <b>{{nodeData.attributes["Closeness"].substring(0,5)}}</b>
                        </div>
                        <div class="betweness">Intermediación:
                            <b>{{nodeData.attributes["Betweenness"].substring(0,5)}}</b>
                        </div>
                        <div class="genero">Género: <b>{{nodeData.attributes["genero"]}}</b></div>
                        <div class="featurings">
                            <b>Featurings: </b>
                            <div class="artist-list">
                                <template v-for="(edge, i) in artistEdges">
                                    <div v-if="(edge.label!=nodeData.label)" :key="i" @click="clickNode(edge.id)">
                                        {{edge.label}}
                                    </div>
                                </template>
                            </div>
                        </div>
                        <!--                        {{(selectedNode!=null)?JSON.stringify(selectedNode):""}}-->
                    </div>
                </div>
            </v-flex>
        </v-layout>
        <div :class="{'black white--text': movil}">
            <v-layout row>
                <v-flex :xs8="!movil">
                    <div class="bottom-leyenda">
                        <ul class="list-leyend">
                            <li class="item-leyend"><span class="dot" :style="{'background-color': colors.COLOR1 }"></span><span>Reguetón</span> </li>
                            <li class="item-leyend"><span class="dot" :style="{'background-color': colors.COLOR2 }"></span><span>Reparto</span> </li>
                            <li class="item-leyend"><span class="dot" :style="{'background-color': colors.COLOR4 }"></span><span>No es reguetonero</span> </li>
                            <li class="item-leyend"><span class="dot" :style="{'background-color': colors.COLOR3 }"></span><span>Extranjero</span> </li>
                        </ul>
                    </div>
                </v-flex>
            </v-layout>
            <v-layout row >
                <v-flex xs8 class="slider" v-if="!movil">
                    <v-slider
                            tick-size="10"
                            class="slider-comp"
                            color="#f600c1"
                            v-model="zoom"
                            step="10"
                            append-icon="zoom_in"
                            prepend-icon="zoom_out"
                            @click:append="zoomIn"
                            @click:prepend="zoomOut"
                            @change="handleZoom"
                    ></v-slider>
                </v-flex>
            </v-layout>
        </div>
    </div>
</template>

<script>
    import sigma from 'sigma'
    import graph from '../../assets/artistasData/data'

    export default {
        name: '',
        data() {
            return {
                selectedNode: null,
                artistEdges: [],
                selected: false,
                defaultLabelThreshold: 10,
                zoom: 50,
                textos: `<p>Para quien está empezando en el mundo del reguetón unirse a uno de los artistas más influyentes puede ser clave para avanzar.</p>

                <p>En este gráfico cada punto representa un artista —de manera individual, no por grupos— y están unidos entre sí cuando han cantado al menos una canción juntos. Los colores identifican el tipo de reguetón que más han interpretado: reparto o reguetón de farándula. Un cantante es más influyente según la cantidad de canciones que tenga con otros que también sean importantes.</p>

                <p>Es evidente la separación en dos comunidades donde los que más se relacionan entre sí son los exponentes del mismo género y entre ellos tiene subgrupos. En el centro se ubican el Chacal y Chocolate quienes han cantado con 58 y 49 artistas respectivamente, de ambas comunidades.</p>

                <p>Entre los reparteros son Kamel y Haryson, después de Chocolate, los más importantes. En la farándula siguen al Chacal, El Micha, Osmany García y el Taiger.</p>

                <p>En el caso de Alexander (7) y Randy Malcom (10), de Gente de Zona, tienen menor puntuación pues la mayoría de los featuring que han hecho son con músicos extranjeros, 24 de 42 artistas foráneos han cantado con ellos.</p>

                <p>En un mundo dominado casi absolutamente por los hombres, también se han unido al reguetón otros 51 artistas cubanos que interpretan otros géneros. Conectarse al reguetón es una buena estrategia para pegar.</p>`,
                camera: {},
                colors: {
                    'COLOR1':'rgb(246,0,193)',
                    'COLOR2':'rgb(13,224,193)',
                    'COLOR3':'#7d0ef2',
                    'COLOR4':'#e5dc16',
                }
            }
        },
        props: ['movil'],
        created() {
            // console.log('add nodexes');
            sigma.classes.graph.addMethod('Nodexes', function (nodeId) {
                var k,
                    neighbors = {},
                    index = this.allNeighborsIndex[nodeId] || {};

                for (k in index)
                    neighbors[k] = this.nodesIndex[k];

                return neighbors;
            });
            sigma.classes.graph.addMethod('neighborsEdgez', function (nodeId) {
                var a = this.allNeighborsIndex[nodeId],
                    eid,
                    target,
                    edges = [];
                for (target in a) {
                    for (eid in a[target]) {
                        edges.push(a[target][eid]);
                    }
                }
                return edges
            });
        },
        mounted() {
            let me = this
            const {sigma_container} = this.$refs;
            this.s = new sigma({
                renderer: {
                    container: sigma_container,
                    type: 'canvas'
                },
                settings: {
                    minEdgeSize: 0.3,
                    maxEdgeSize: 0.3,
                    minNodeSize: 2,
                    maxNodeSize: 15,
                    font: 'Andada',
                    zoomMax: 1.2,
                    hoverFontStyle: "bold",
                    labelThreshold: this.labelThreshold,
                    defaultEdgeType: "curve",
                    mouseWheelEnabled: false
                }
            });

            this.camera = this.s.camera

            this.s.bind("clickNode", (node) => {
                this.clickNode(node)
            });
            // this.s.bind("clickStage", (node) => {
            //     this.selectedNode = null
            //     this.selected = false
            //     this.restartNode()
            // });
            this.s.bind("overNode", (node) => {
                if (!me.selected) {
                    me.hoverNode(node)
                }
            });
            this.s.bind('outNode', function () {
                if (!me.selected) {
                    me.restartNode()
                }
            });
            this.s.graph.read(graph);
            for (let n of this.s.graph.nodes()){
                if(n.attributes.tipo == 'extranjero'){
                    n.color ='#7d0ef2'
                } else if(n.attributes.tipo == 'no_reggaeton'){
                    n.color ='#e5dc16'
                }
            }
            this.s.cameras[0].goTo({x: 0, y: 0, angle: 45, ratio: 0.50});
            this.s.refresh();
        },
        computed: {
            nodeData() {
                return this.selectedNode.data.node
            },
            labelThreshold() {
                return (this.movil) ? 15 : 10
            },
            ratio() {
                switch (this.zoom) {
                    case 0:
                        return 1.8
                    case 10:
                        return 0.99284999999998
                    case 20:
                        return 0.883211925749999
                    case 30:
                        return 0.7274508034345994
                    case 40:
                        return 0.6717410706145774
                    case 50:
                        return 0.5
                    case 60:
                        return 0.4347209603722878
                    case 70:
                        return 0.33807115316016934
                    case 80:
                        return 0.1988653842118643
                    case 90:
                        return 0.1169796377716849
                    case 100:
                        return 0.06881155163040288
                }
                return 0;
            }
        },
        methods: {
            handleZoom() {
                // Zoom out - animation :
                sigma.misc.animation.camera(this.camera, {
                    ratio: this.ratio
                }, {
                    duration: 200
                });
            },
            zoomOut() {
                this.zoom = (this.zoom - 10) || 0
                this.handleZoom()
            },
            zoomIn() {
                this.zoom = (this.zoom + 10) || 100
                this.handleZoom()
            },
            closeDetail() {
                this.selectedNode = null
                this.selected = false
                this.restartNode()
            },
            clickNode(node) {
                if (typeof node == 'string') {
                    node = {data: {node: this.s.graph.nodes(node)}}
                }
                this.selectedNode = node
                this.selected = true
                this.restartNode()
                this.artistEdges = this.hoverNode(node)
            },
            restartNode() {
                this.s.graph.nodes().forEach(function (n) {
                    n.hidden = false
                });
                this.s.graph.edges().forEach(function (e) {
                    e.color = "rgb(192,192,192)";
                });
                this.s.refresh();
            },
            hoverNode(node) {
                let id = node.data.node.id,
                    toKeep = this.s.graph.Nodexes(id),
                    edgesToKeep = this.s.graph.neighborsEdgez(id)

                toKeep[id] = node.data.node

                this.s.graph.nodes().forEach(function (n) {
                    if (!toKeep[n.id]) {
                        n.hidden = true;
                    }
                });

                for (let edge of edgesToKeep) {
                    this.s.graph.edges(edge.id).color = "#000";
                }

                this.s.refresh()
                return toKeep
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .dot {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 5px;
    }
    .name-singer {
        font-size: 14px;
        text-transform: uppercase;
        font-family: Andada-Bold;
        border-bottom: 1px solid #FFF;
        margin-bottom: 10px;
        padding-bottom: 10px;
    }

    .grafico-relaciones-container {
        background-color: white;
    }

    .slider-comp {
        width: 50%;
        margin: 0px auto;
    }

    .slider {
        margin: 0px 0px 20px 0px;
        text-align: center;
    }

    .bottom-leyenda{
        text-align: center;
    }

    .bottom-leyenda ul {
        display: inline-block;
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    .bottom-leyenda li {
        float: left;
        padding: 14px 16px;
        text-decoration: none;
        font-family: "Andada";
        font-size: 12px;
        text-transform: uppercase;
    }

    .cell-data {
        margin-top: 20px;
    }

    .cell-map {
        margin-right: 0px;
        background: white;
    }

    .cell-map-movil {
        margin-right: 50px;
    }

    .full-row {
        background: black;
        background: url('/img/artistas/artistas-textura-bkg.jpg') repeat-y center;
    }

    .genero {
        text-transform: capitalize;
        border-bottom: 1px solid #FFF;
        margin-bottom: 10px;
        padding-bottom: 10px;
    }

    .leyenda-movil {
        margin-top: 0 !important;
    }

    .leyenda, .leyenda-movil, .detalles, .detalles-movil {
        width: 95%;
        background-color: #000000;
        color: white;
        margin-top: 10px;
        padding: 14px;
    }

    .leyenda-movil {
        width: 100%;
    }

    .detalles-movil {
        position: fixed;
        width: 100%;
        z-index: 100;
        top: 0px;
        left: 0px;
        height: unset;
        bottom: 0;
        margin: 0;
        background-color: rgba(0, 0, 0, .9);
    }

    .back-inactive {
        background: url("/img/menu/toolbar-gradient.png");
        background-size: 100%;
    }

    .title-leyenda, .title-detalles {
        font-family: Andada-Bold;
        text-transform: uppercase;
        font-size: large;
    }

    .artist-list {
        cursor: pointer;
        text-transform: uppercase;
        margin-left: 10px;
    }

    .text-leyenda {
        /* font-family: "Andada"; */
        overflow: auto;
        margin: 20px 0px 10px 10px;
        padding: 10px;
        /* max-height: 300px; */
    }

    @media only screen and (min-width: 1264px) {
        .text-leyenda {
            max-height: 300px;
        }
    }

    .text-detalles, .text-detalles-movil {
        margin: 20px 0px 10px 10px;
        font-family: "Andada";
        max-height: 300px;
        overflow: auto;
    }

    .text-detalles-movil {
        max-height: 75vh;
    }

    ::-webkit-scrollbar {
        width: 4px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    #sigma-container {
        height: 75vh;
        /*width: 90%;*/
        /*border: 10px solid #000000;*/
    }

</style>
