<template>
    <section sticky-container id='graficoPosicionScrolly'>
        <div on-stick="onStick" v-sticky sticky-offset="offset" class="chart-container">
            <highcharts :options="chartPosicion" id="graficoPosicion"
                        ref="graficoPosicion"></highcharts>
        </div>
        <div v-if="sticked" class="sapcer"></div>
        <article>
            <div class='grafico-posicion-step' data-step='anual'>
                <p>Mientras más famosa es una canción, más reconocido es el artista. Por eso, para saber cuándo una canción es popular los especialistas y los medios de comunicación elaboran listas de éxitos, con frecuencia semanal, mensual o anual; y sobre todo miden por cuánto tiempo están en el top.</p>
                <p>Para realizar el ranking anual se calculó la posición de cada una de las canciones según el top 100 anual del sitio Pista Cubana y el Top Música Urbana y reguetón – National AirPlay Cuba y a cada autor se le suman los puntos de cada una de sus canciones.</p>
                <p>Alexander y Randy Malcom de Gente de Zona están en los primeros lugares, el primero estuvo 23 veces en listas con 18 canciones y el segundo 21 veces en lista con 16. Los temas Sarte del satén y Otra noche más, de Alexander con Jacob Forever y el Cata hacen la diferencia.  La canción Bailando estuvo en primer lugar de los años 2013 y 2014. </p>
                <p>La tercera posición la ocupa Jacob Forever, que luego de su salida de Gente de Zona en marzo de 2013, continuó pegando temas y estuvo 14 veces en listas, su tema más popular Hasta que se seque el Malecón estuvo en el segundo lugar de 2015.</p>

            </div>
            <div class='grafico-posicion-step' data-step='mensual'>
                <p>El top mensual se realizó a partir de las listas de Adriano DJ en la revista Vistar y del youtuber Adrián Fernández y Abdel la Esencia. En este caso se divide uno entre cada posición mensual por canción, de las listas, donde los más cercanos a la primera obtienen las mejores puntuaciones. El resultado se divide entre doce para que los rangos sean menores a los anuales y poder sumar todos los valores.</p>
                <p>El Chacal aparece 64 veces en lista y 6 veces ocupa el primer lugar con canciones distintas.</p>
                <p>Sin embargo, este método se puede parcializar e incluir o excluir a un artista, según la opinión de quien elabore la lista y sus gustos personales e intereses. Entonces, preguntar directamente a las personas es importante.</p>

            </div>
            <div class='grafico-posicion-step' data-step='manual'>
                <p>Por eso tuvimos en cuenta la opinión de 53 personas en La Habana quienes listaron las 10 mejores canciones durante el período para ellas. En total se mencionaron 93 artistas, 54 de reguetón y 39 de reparto, que adquiere mayor protagonismo. Chocolate en el medio de los mundos domina esta lista con 39 canciones.</p>
                <p>Este gráfico recoge la elección de las mejores 10 canciones a partir de una encuesta a 53 personas en
                    La Habana.</p>
            </div>
            <div class='grafico-posicion-step last-step' data-step='internet'>
                <p>Además, están los resultados de una encuesta web en la que participaron 329 personas a partir de una lista previamente elaborada de la conjunción de las anteriores. En este caso se dividió 1 entre 65 que fue el mayor número de votos alcanzados.</p>
                <p>Las canciones más votadas fueron: El Chupi Chupi (65), Bajanda (58), El oro es mío (55), Aceite y agua (54) y el Campismo (52). De las cinco primeras Chocolate canta 4, sin embargo, el mejor posicionado es el Chacal porque tiene más canciones (108) con mejores puntuaciones en total.</p>
                <p>Entre los 10 primeros de las listas de éxitos y de las encuestas hay diferencias. En las listas el reparto casi no se incluye y en las encuestas sí. Alexander y Randy Malcom tiene las primeras posiciones en las listas y Chocolate en las encuestas. Aunque en general la mejor posición entre todos los indicadores la tiene El Chacal, quien ha estado en las listas 81 veces con 33 canciones y, además, salió de segundo en las encuestas. </p>
                <p>El top final de posición se calculó a partir de la mayor cantidad de veces repetidas de un artista en las mejores posiciones.</p>

            </div>
        </article>
    </section>
</template>
<script>
    // let Highcharts = require('highcharts');
    import {Chart} from 'highcharts-vue'
    import scrollama from 'scrollama'
    import * as d3 from 'd3'

    export default {
        name: "",
        data: () => ({
            offset: {top: 140, bottom: 18},
            anualData: [],
            mensualData: [],
            manualData: [],
            internetData: [],
            categories: [],
            scroller: null,
            scrolly: null,
            sticked: false,
            chart: null,
            graficoSteps: null,
            step: null,
            xAxisMax: 10,
            xAxisMin: 0,
            chartPosicion: {},
            active: 'anual',
            activeChartData: [],
            currentColor: "#0de0c1",
            currentTitle: 'Listas de posición anual',
            titles: {
                'TITLE1': 'LISTAS DE POSICIÓN ANUAL',
                'TITLE2': 'LISTAS DE POSICIÓN MENSUAL',
                'TITLE3': 'ENCUESTA MANUAL',
                'TITLE4': 'ENCUESTA WEB',
            },
            colors: {
                'COLOR1': 'rgb(246,0,193)',
                'COLOR2': 'rgb(13,224,193)',
                'COLOR3': '#7d0ef2',
                'COLOR4': '#e5dc16',
            }
        }),
        components: {
            highcharts: Chart
        },
        props: {
            graficoData: {
                type: [Array, Object]
            }
        },
        watch: {
            graficoData(value) {
                this.loadArtistasPosicion(value);
            }
        },
        methods: {
            onStick(data) {
                this.sticked = data.sticked
            },
            loadArtistasPosicion(data) {
                for (let a of data) {
                    this.anualData.push({"nombre": a.nombre, "valor": a.anual})
                    this.mensualData.push({"nombre": a.nombre, "valor": a.mensual})
                    this.manualData.push({"nombre": a.nombre, "valor": a.manual})
                    this.internetData.push({"nombre": a.nombre, "valor": a.internet})
                }
                this.sortChartData(this.anualData)
                this.sortChartData(this.mensualData)
                this.sortChartData(this.manualData)
                this.sortChartData(this.internetData)

                // this.getChartCategories(this.anualData)
                // this.getChartData(this.anualData)

                this.setActiveChartData('anual', this.anualData);
            },
            sortChartData(data) {
                data.sort((a, b) => {
                    return (a.valor < b.valor) ? 1 : ((a.valor > b.valor) ? -1 : 0)
                })
            },
            getChartCategories(data) {
                this.categories = []
                for (let n of data) {
                    this.categories.push(n.nombre)
                }
            },
            getChartData(data) {
                this.activeChartData = []
                for (let n of data) {
                    this.activeChartData.push(n.valor)
                }
            },
            setActiveChartData(type, data) {
                // this.chartPosicion.series[0].name = 'Encuesta ' + type
                if (type === "anual") {
                    this.currentColor = this.colors.COLOR1
                    this.currentTitle = this.titles.TITLE1
                }
                if (type === "mensual") {
                    this.currentColor = this.colors.COLOR2
                    this.currentTitle = this.titles.TITLE2

                }
                if (type === "manual") {
                    this.currentColor = this.colors.COLOR3
                    this.currentTitle = this.titles.TITLE3

                }
                if (type === "internet") {
                    this.currentColor = this.colors.COLOR4
                    this.currentTitle = this.titles.TITLE4

                }

                this.getChartCategories(data)
                this.chartPosicion.xAxis.categories = this.categories
                this.getChartData(data)
                this.chartPosicion.title.text = this.currentTitle
                this.chartPosicion.series = [{
                    color: this.currentColor,
                    name: 'Posición del artista según la suma de sus canciones',
                    borderColor: 'transparent',
                    data: this.activeChartData
                }]

            },
            // scrollama event handlers
            handleStepEnter(response) {
                this.step.classed('is-active', function (d, i) {
                    return i === response.index;
                });
                switch (response.index) {
                    case 0:
                        this.setActiveChartData('anual', this.anualData)
                        break
                    case 1:
                        this.setActiveChartData('mensual', this.mensualData)
                        break
                    case 2:
                        this.setActiveChartData('manual', this.manualData)
                        break
                    case 3:
                        this.setActiveChartData('internet', this.internetData)
                        break
                }
            },
            handleStepExit(response) {
                let current_el = this.step.classed('is-active', function (d, i) {
                    return i === response.index;
                });
                current_el.style('height', 'auto', 'important');
            },
            init() {

                // 1. force a resize on load to ensure proper dimensions are sent to scrollama
                // 2. setup the scroller passing options
                // 		this will also initialize trigger observations
                // 3. bind scrollama event handlers (this can be chained like below)
                this.scroller.setup({
                    step: '#graficoPosicionScrolly article .grafico-posicion-step',
                    // offset: 0.25,
                })
                    .onStepEnter(this.handleStepEnter)
                // setup resize event
            }
        },
        mounted() {
            // let vm = this;
            // this.loadArtistasPosicion()

            let main = d3.select('main');
            this.scrolly = main.select('#graficoPosicionScrolly');
            this.chart = this.scrolly.select('#graficoPosicion');
            this.graficoSteps = this.scrolly.select('article');
            this.step = this.graficoSteps.selectAll('.grafico-posicion-step');

            this.scroller = scrollama();

            this.init();

            this.chartPosicion = {
                chart: {
                    type: 'bar',
                    width: '100%',
                    backgroundColor: "transparent"
                },
                navigation: {
                    buttonOptions: {
                        theme: {
                            'stroke-width': 0,
                            'fill': 'transparent'
                        }
                    }
                },
                credits: {
                    enabled: false
                },
                exporting: {
                    enabled: false
                },
                legend: {
                    enabled: true,
                    align: 'center'
                },
                pane: {
                    size: 500
                },
                title: {
                    text: '',
                    style: {fontFamily: 'Andada'}

                },
                xAxis: {
                    categories: null,
                    max: this.xAxisMax,
                    min: this.xAxisMin
                },
                yAxis: {
                    title: {
                        text: ''
                    }
                },
                plotOptions: {
                    series: {
                        animation: {
                            duration: 1000
                        }
                    }
                },
                series: null,
            }
        }
    }
</script>
<style lang="scss" scoped>
    #graficoPosicionScrolly {
        background-color: #f3f3f3;
        padding: 1rem;
    }

    .sapcer {
        width: 70%
    }

    .chart-container {
        width: 60% !important;
        /*height: 200px;*/
        /*background-color: #1c75b7;*/

    }

    article {
        position: relative;
        padding: 0 1rem;
        width: 40%;
        margin-left: auto;
    }

    .grafico-posicion-step {
        font-family: 'Segoe';
        color: #000;
    }

    .grafico-posicion-step:last-child {
        margin-bottom: 0;
    }

    .grafico-posicion-step p {
        text-align: left;
        padding: 1rem;
        font-size: 14px;
        font-weight: 400;
        margin: 0;
    }
</style>
