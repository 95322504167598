<template>
    <div style="background-color: white">
        <highcharts :options="chartPosicion" class="grafico-encuestas-mobile"></highcharts>
    </div>
</template>
<script>
    import {Chart} from 'highcharts-vue'
    import ArtistasCubanos from '../../assets/artistasData/artistspos'

    export default {
        name: "",
        components: {
            highcharts: Chart
        },
        props: {
            chartType: {
                type: String,
                default: ''
            },
            color: {
                type: String,
                default: ''
            }
        },
        data: () => ({
            chartData: [],
            categories: [],
            chartPosicion: {},
            values: []
        }),
        mounted() {
            let vm = this
            this.setChartData()

            this.chartPosicion = {
                chart: {
                    type: 'bar',
                    backgroundColor: "#FFF"
                },
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 500
                        },
                        chartOptions: {
                            legend: {
                                enabled: false
                            }
                        }
                    }]
                },
                navigation: {
                    buttonOptions: {
                        theme: {
                            'stroke-width': 0,
                            'fill': 'transparent'
                        }
                    }
                },
                credits: {
                    enabled: false
                },
                exporting: {
                    enabled: false
                },
                legend: {
                    enabled: false,
                },
                pane: {
                    size: 500
                },
                title: {
                    text: '',
                    style: {fontFamily: 'Andada'}

                },
                xAxis: {
                    categories: vm.categories,
                    max: 11
                    // max: vm.xAxisMax,
                    // min: vm.xAxisMin
                },
                yAxis: {
                    title: {
                        text: ''
                    }
                },
                plotOptions: {
                    series: {
                        animation: {
                            duration: 1000
                        }
                    }
                },
                series: [{
                    color: vm.color,
                    borderColor: 'transparent',
                    data: vm.values
                }]
            }
        },
        methods: {
            setChartData() {
                for (let a of ArtistasCubanos) {
                    this.pushChartData(a)
                }
                this.sortChartData()
                this.getChartCategories()
                this.getChartValues()
            },
            pushChartData(a) {
                switch (this.chartType) {
                    case "anual":
                        this.chartData.push({"nombre": a.nombre, "valor": a.anual})
                        break
                    case "mensual":
                        this.chartData.push({"nombre": a.nombre, "valor": a.mensual})
                        break
                    case "manual":
                        this.chartData.push({"nombre": a.nombre, "valor": a.manual})
                        break
                    case "internet":
                        this.chartData.push({"nombre": a.nombre, "valor": a.internet})
                        break
                }
            },
            sortChartData() {
                this.chartData.sort((a, b) => {
                    return (a.valor < b.valor) ? 1 : ((a.valor > b.valor) ? -1 : 0)
                })
            },
            getChartCategories() {
                for (let n of this.chartData) {
                    this.categories.push(n.nombre)
                }
            },
            getChartValues() {
                for (let n of this.chartData) {
                    this.values.push(n.valor)
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
</style>
