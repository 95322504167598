<template>
  <div class="perfilArtista">
    <v-container v-if="perfilArtista">
      <h4 class="title-font font-italic">{{ nombreArtista }}</h4>
      <span class="artista-perfil-label text-font">Nombre: </span>
      <span class="title-font artista-pefil-data">{{ perfilArtista.nombre }}</span>
      <v-spacer></v-spacer>
      <span class="artista-perfil-label text-font">Nacimiento: </span>
      <span class="title-font artista-pefil-data">{{ perfilArtista.nacimiento }}</span>
      <v-spacer></v-spacer>
      <span class="artista-perfil-label text-font">Otros apodos: </span>
      <span class="title-font artista-pefil-data"> {{ perfilArtista.otros_apodos }}</span>
      <v-spacer></v-spacer>
      <span class="artista-perfil-label text-font">Período de actividad: </span>
      <span class="title-font artista-pefil-data"> {{ perfilArtista.periodo_actividad }}</span>
      <v-spacer></v-spacer>
      <div class="artista-grupos">
        <span class="artista-grupos-label artista-perfil-label text-font">Grupos: </span>
        <span v-for="(grupo, i) in perfilArtista.grupos" :key='i' class="title-font artista-pefil-data">
          {{ grupo }}
          <v-spacer></v-spacer>
        </span>
       </div>
      <span class="title-font artista-pefil-data" v-if="viewEnlace"><a class="sabermas-link" target="_blank" rel="noreferrer" :href="perfilArtista.enlace">Saber más</a></span>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "ArtistaPerfil",
  props: {
    nombreArtista: {
      type: String,
      default: ''
    },
    perfilArtista: {
      type: Object
    }
  },
  computed: {
    viewEnlace () {
      if (this.perfilArtista.hasOwnProperty("enlace")) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.perfilArtista {
  background-color: rgba(57, 158, 176, 0.70);
  height: 27vw;
  max-width: 20vw;
  margin: 2vw 0 0 5vw;
  color: white;
  font-size: 12px;
  text-align: right;
  padding-top: 2vw;
  line-height: 2;
}

.artista-perfil-label {
  font-style: italic;
}

.artista-grupos {
  line-height: 2;
}

.artista-grupos-label {
  margin-left: 3vw;
}

.sabermas-link {
  color: white;
  text-decoration: none;
}

@media only screen and (min-device-width: 0px) and (max-device-width: 740px) {
    .perfilArtista {
      // height: 70vh;
      height: unset;
      // width: 100%;
      max-width: unset;
      font-size: 13px;
      // padding: 15vw 14vw 0 0;
      padding: 0;
      letter-spacing: 1px;
      margin: 0;
      word-spacing: 2px;
    }
}
</style>
