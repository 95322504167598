<template>
  <v-container class="artistas-desktop">
    <section>
      <v-flex xl12 class="artistas-desktop-header">
        <!-- <video preload="yes" autoplay loop muted playsinline class="main-video">
          <source src="/img/artistas/artistas-desktop-header.webm" type="video/webm" />
          <source src="/img/artistas/artistas-desktop-header.mp4" type="video/mp4" />
        </video> -->
        <v-img src="/img/artistas/artistas-desktop-header.gif" id="artistas-desktop-img"></v-img>
        <v-layout class="artistas-header-content">
          <v-flex class="artistas-header-text">
            <p v-html="headerText"></p>
          </v-flex>
          <v-flex class="artistas-header-photos" md3>
            <v-carousel
              hide-delimiters
              hide-controls
              height="32vw"
              :interval="interval"
              :cycle="cycle"
              ref="fotosSlide"
            >
              <v-carousel-item
                class="artista-photo-desktop"
                v-for="(item,i) in images"
                :key="i"
                :src="item"
              >
                <v-expand-transition>
                  <perfil-artista
                    v-if="viewPerfilArtista"
                    :nombreArtista="nombreArtista"
                    :perfilArtista="perfilArtista"
                  />
                </v-expand-transition>
              </v-carousel-item>
            </v-carousel>
          </v-flex>
          <v-flex class="artistas-header-photos-carousel">
            <PhotoCarousel
              todosArtistas
              v-on:handleAvatarOnClick="displayArtistaInfo"
              :data="artistas"
              color="gold"
            />
          </v-flex>
        </v-layout>
      </v-flex>
    </section>
    <article class="artistas-desktop-content">
      <v-flex class="graficos-artistas grafico-relaciones-desktop">
        <graficos-title tipo="relaciones" />
        <!-- <div class="bar-black"></div> -->

        <!-- <v-spacer></v-spacer> -->
        <grafico-relaciones />
      </v-flex>
      <v-layout class="graficos-top-artistas" id="topArtistas-grafo">
        <v-flex class="artistas-relaciones-photos-carousel artistas-top-carousel">
          <PhotoCarousel
            topRanking
            :data="topFive"
            firstActive
            color="white"
            v-on:handleAvatarOnClick="graphArtistaTopInfo"
          />
        </v-flex>
        <artistas-top
          paddingRight
          :artistaTopData="graphActiveArtist"
          chartType="grafo"
          idPerfil="perfil-topArtistas-grafo"
          classNamePerfilFoto="perfilFoto-topArtistas-grafo"
        />
      </v-layout>

      <v-flex class="graficos-artistas grafico-posicion-desktop">
        <graficos-title tipo="posicionamiento" />
        <!-- <div class="bar-black"></div> -->

        <!-- <v-spacer></v-spacer> -->
        <grafico-posicion :graficoData="posicion" />
      </v-flex>
      <v-layout class="graficos-top-artistas" id="topArtistas-posicion">
        <artistas-top
          paddingLeft
          :artistaTopData="positionActiveArtist"
          chartType="posicion"
          idPerfil="perfil-topArtistas-posicion"
          classNamePerfilFoto="perfilFoto-topArtistas-posicion"
        />
        <v-flex class="artistas-posicion-photos-carousel artistas-top-carousel">
          <PhotoCarousel
            topRanking
            :data="posTopFive"
            firstActive
            color="white"
            v-on:handleAvatarOnClick="positionArtistaTopInfo"
          />
        </v-flex>
      </v-layout>

      <v-flex class="graficos-artistas grafico-premios-desktop">
        <graficos-title tipo="premios" />
        <!-- <div class="bar-black"></div> -->
        <!-- <v-spacer></v-spacer> -->
        <grafico-premios :graficoData="premios" />
      </v-flex>
      <v-layout class="graficos-top-artistas" id="topArtistas-premios">
        <v-flex class="artistas-premios-photos-carousel artistas-top-carousel">
          <PhotoCarousel
            topRanking
            color="white"
            :data="premios"
            firstActive
            v-on:handleAvatarOnClick="premiosArtistaTopInfo"
          />
        </v-flex>
        <artistas-top
          paddingRight
          :artistaTopData="premiosActiveArtist"
          chartType="premios"
          idPerfil="perfil-topArtistas-premios"
          classNamePerfilFoto="perfilFoto-topArtistas-premios"
        />
      </v-layout>

      <v-flex class="graficos-artistas grafico-redes-desktop">
        <graficos-title tipo="redes sociales" />
        <!-- <v-spacer></v-spacer> -->
        <grafico-redes :graficoData="redes" />
      </v-flex>
      <v-layout class="graficos-top-artistas" id="topArtistas-redes">
        <artistas-top
          paddingLeft
          :artistaTopData="redesActiveArtist"
          chartType="redes"
          idPerfil="perfil-topArtistas-redes"
          classNamePerfilFoto="perfilFoto-topArtistas-redes"
        />
        <v-flex class="artistas-redes-photos-carousel artistas-top-carousel">
          <PhotoCarousel
            topRanking
            :data="redesTopFive"
            firstActive
            color="white"
            v-on:handleAvatarOnClick="redesArtistaTopInfo"
          />
        </v-flex>
      </v-layout>

      <v-layout class="top-tres-artistas">
        <v-expand-transition>
          <v-flex class="top-tres-artistas-main-photo">
            <v-img :src="currentArtistaTopTres.mainFoto" id="photo-artista-top-tres"></v-img>
            <v-layout id="top-tres-nombre-artista">
              <span class="title-font top-tres-nombre-artista">{{ currentArtistaTopTres.nombre }}</span>
              <a
                :href="getEnlace"
                v-if="getEnlace !== '#chacal'"
                @click="clickLink"
                target="_blank"
                rel="noreferrer"
                class="top-tres-artista-more-info text-body"
              >
                leer
                más
              </a>
              <a
                class="top-tres-artista-more-info ma-0 pa-0 text-body"
                @click="clickLink"
                v-if="getEnlace === '#chacal'"
              >leer más</a>
            </v-layout>
          </v-flex>
        </v-expand-transition>
        <v-layout class="top-tres-presentation">
          <v-layout column class="artistas-top-tres-container">
            <v-img
              :src="artistaTopTres.circle"
              class="photo-artista-top-tres"
              v-for="(artistaTopTres, i) in artistasTopTresPhotos"
              :key="i"
              @click="changeArtistaTopTres($event, i)"
            ></v-img>
          </v-layout>
          <v-img
            v-if="!activechacal"
            src="/img/artistas/top-tres/titulo.png"
            id="title-artistas-top-tres"
          ></v-img>
          <div class="chacal-textd" v-else>
            <p>
              Ramón Lavado Martínez, El Chacal, en un show, en Cuba, República Dominicana, Estados
              Unidos o Italia, se tira en el piso y perrea reguetón. Tiene unos movimientos de cintura
              sueltos,
              sexuales. Recoge los blúmeres y sujetadores que le lanzan las fanáticas desde el público y se
              los
              restriega por el cuerpo. El Chacal, que nació en el barrio “El Hueco”, en La Lisa, el 8 de
              febrero
              de 1986 y debutó en la música como cantante de hip hop, filma de loco y cae bien.
            </p>
            <p>
              En el año 2003 formó su propio proyecto llamado Los Chavos junto a su colega el Señor
              Rodríguez. Recorrió el país en varias giras nacionales hasta ser llamado por Baby Lores para
              formar parte del prestigioso grupo Clan 537; perteneciente al Catálogo Artístico de la Empresa
              Benny Moré.
            </p>
            <p>
              Cuando todavía no era reconocido se presentó tirándole al Insurrecto —que era el mejor rapero
              del reguetón en esa época— con una canción que decía: “ya se ablandó la yuca/ así que cuál es la
              disputa/ mira que se soltó/ el Chacal, el hijo de puta”. A partir de entonces tuvo todas las
              miradas
              sobre él y comenzó un período llamado popularmente “la tiradera” entre Baby Lores, El Chacal e
              Insurrecto. El Chacal era versátil: cantaba, rapeaba, componía. Junto a Lores disparó Tiradera
              para el Insurrecto (Ahù) en 2008, canción icónica dentro del género urbano cubano.
            </p>

            <p>
              Después de tres producciones discográficas emprendió su carrera como solista firmando con la
              compañía Hitown Entertainment en 2009. Los dos discos que produjo en esta época —Reporte y
              El Clave— tienen colaboraciones con artistas como Haila Mompié, la Charanga Habanera,
              Yulién Oviedo.
            </p>
            <p>
              Luego se unió a Luis Javier Prieto Cedeño y crearon el dúo Chacal y Yakarta. Canciones como
              El tubazo, Besito con lengua, La cañandonga, Diciembre, Sexo y Ellas son locas posicionaron a
              los reguetoneros entre los favoritos del público. En 2015 se vieron envueltos en una polémica
              cuando la empresa norteamericana All Star Promotions los denunció por supuestas acciones
              fraudulentas y estafas. La firma aseguró, a través de un comunicado, que el dúo se separaba
              después de ellos invertir una cuantiosa suma de dinero en sus carreras. A finales de este año
              realizaron sus últimas presentaciones en Estados Unidos y ponen fin al dueto.
            </p>
            <p>
              En 2016, El Chacal despegó a la fama internacional debido al éxito del tema Ay, mi Dios junto a
              Yandel, Pitbull y DJ Chino. Tuvo presentaciones en escenarios como Nuestra Belleza Latina y
              Premios Juventud.
            </p>
            <p>
              También ha incursionado en la bachata. No te vayas, El reloj, Procuro olvidarte son algunos
              temas en los que se nombra “El Chacal de la bachata”. Además, grabó con cantantes reparteros
              como Harryson para el tema La botella y con El Negrito, Kokito, Munu Manu para Maltrataíta y
              loca. Durante los Premios Juventud 2018 dedicados al género urbano de Cuba, se presentó con
              Calentando La Habana, el primer escenario importante donde tocan un tema con pedal
              —característica musical esencial del reparto.
            </p>

            <p>
              Sin embargo, respecto a las colaboraciones cuenta Luismer Nieblas, representante de DJ Unic:
              “una vez, en el estudio Célula Music, le ofrecieron 10 mil CUC en efectivo para que grabara con
              unos muchachos que estaban empezando. El Chacal se negó. Otras veces ha pasado que los
              ῾chamaquitosʼ están grabando, a él le gusta el tema y lo hace”.
            </p>
            <p>
              No obstante, son cuestionados los precios de las entradas para sus conciertos en Cuba. El 25 de
              agosto de 2019, el artista tuvo presentación en el Hotel Las Cuevas, Trinidad, donde el precio
              por
              persona era de 15 CUC y había ofertas que oscilaban entre los 200 CUC y 500 CUC para el
              público VIP.
            </p>
            <p>
              Con la explosiva y extendida trayectoria dentro del género, Chanchi, como lo llaman sus amigos,
              ha logrado un público amplio y fiel y que lo ubican, sin dudas, como el rey del trono; al menos
              en 2018.
            </p>
          </div>
        </v-layout>
      </v-layout>
    </article>
    <!-- <ComentariosModal v-if="$store.getters.showModal">
            <ComentariosForm></ComentariosForm>
    </ComentariosModal>-->
    <!-- <v-layout class="comentarios">
            <v-flex sm6>
                <ListaComentarios/>
            </v-flex>
            <ComentariosForm/>
    </v-layout>-->
  </v-container>
</template>
<script>
import PhotoCarousel from "../PhotoCarousel";
import GrafoRelaciones from "./GrafoRelaciones";
import GraficoPremios from "./GraficoPremios";
import GraficoPosicion from "./GraficoPosicion";
import GraficoRedes from "./GraficoRedes";
import GraficosTitle from "./GraficosTitle";
import ArtistaPerfil from "./ArtistaPerfil";
import ArtistasTop from "./ArtistasTop";
// import ComentariosForm from '../../components/Comentarios/ComentariosForm'
// import ListaComentarios from '../../components/Comentarios/ListaComentarios'
// import ComentariosModal from '../../components/Comentarios/ComentariosModal'

export default {
  name: "",
  components: {
    PhotoCarousel,
    "grafico-premios": GraficoPremios,
    "grafico-posicion": GraficoPosicion,
    "grafico-relaciones": GrafoRelaciones,
    "graficos-title": GraficosTitle,
    "perfil-artista": ArtistaPerfil,
    "artistas-top": ArtistasTop,
    "grafico-redes": GraficoRedes
    // ComentariosForm,
    // ListaComentarios,
    // ComentariosModal
  },
  props: {
    headerText: {
      type: String,
      default: ""
    },
    datosArtistas: {
      type: Object
    }
  },
  computed: {
    getEnlace() {
      if (this.currentArtistaTopTres.nombre !== "El Chacal") {
        return this.currentArtistaTopTres.enlace;
      }
      return "#chacal";
    },
    artistas() {
      return this.datosArtistas.artistas || [];
    },
    topFive() {
      const { grafo } = this.datosArtistas;
      return grafo ? grafo.topFive : [];
    },
    posicion() {
      const { posicion } = this.datosArtistas;
      return posicion ? posicion.general : [];
    },
    posTopFive() {
      const { posicion } = this.datosArtistas;
      return posicion ? posicion.topFive : [];
    },
    premios() {
      return this.datosArtistas.premios || [];
    },
    redes() {
      const { redes } = this.datosArtistas;
      return redes ? redes.general : {};
    },
    redesTopFive() {
      const { redes } = this.datosArtistas;
      return redes ? redes.topFive : [];
    }
  },
  data: () => ({
    // artistas: [],
    nombreArtista: "",
    activechacal: false,
    artistaFoto: "",
    perfilArtista: {},
    viewPerfilArtista: false,
    currentArtistaTopTres: "",
    artistasTopTresPhotos: [
      {
        nombre: "El Chacal",
        mainFoto: "/img/artistas/top-tres/chacal.png",
        circle: "/img/artistas/top-tres/chacal-circulo.png"
      },
      {
        nombre: "Alexander",
        mainFoto: "/img/artistas/top-tres/alexander.png",
        circle: "/img/artistas/top-tres/alexander-circulo.png",
        enlace:
          "http://www.granma.cu/cultura/2018-02-27/el-sonido-de-una-epoca-27-02-2018-23-02-59"
      },
      {
        nombre: "Chocolate",
        mainFoto: "/img/artistas/top-tres/chocolate.png",
        circle: "/img/artistas/top-tres/chocolate-circulo.png",
        enlace: "https://www.magazineampm.com/el-evangelio-segun-san-choco/"
      }
    ],
    graphActiveArtist: {},
    positionActiveArtist: {},
    premiosActiveArtist: {},
    redesActiveArtist: {},
    images: [],
    // imageIndex: 0,
    cycle: true,
    interval: "3000",
    currentItem: {}
  }),
  // beforeMount() {

  // },
  mounted() {
    // this.getImagesForSlide();
    // this.$scrollmagic.addScene(this.revealPerfilGrafo());
    // this.$scrollmagic.addScene(this.revealPerfilFotoGrafo());
    // this.$scrollmagic.addScene(this.revealPerfilPosicion());
    // this.$scrollmagic.addScene(this.revealPerfilFotoPosicion());
    // this.$scrollmagic.addScene(this.revealPerfilPremios());
    // this.$scrollmagic.addScene(this.revealPerfilFotoPremios());
    // this.$scrollmagic.addScene(this.revealPerfilRedes());
    // this.$scrollmagic.addScene(this.revealPerfilFotoRedes());
  },
  watch: {
    datosArtistas(value) {
      const { grafo, premios, posicion, redes, artistas } = value;
      if (!(grafo && premios && posicion && redes && artistas)) return;
      this.graphActiveArtist = grafo.topFive[0];
      this.premiosActiveArtist = premios[0];
      this.positionActiveArtist = posicion.topFive[0];
      this.redesActiveArtist = redes.topFive[0];
      this.nombreArtista = artistas[0].nombre;
      this.perfilArtista = artistas[0].perfil;
      this.artistaFoto = artistas[0].perfilFoto;
      this.currentArtistaTopTres = this.artistasTopTresPhotos[0];
      this.images = artistas.map(artista => artista.perfilFoto);
    }
  },
  methods: {
    clickLink() {
      if (this.currentArtistaTopTres.nombre == "El Chacal") {
        this.activechacal = true;
      } else {
        this.activechacal = false;
      }
    },
    // getImagesForSlide() {
    //   for (let artista of this.datosArtistas.artistas) {
    //     this.images.push(artista.perfilFoto);
    //   }
    // },
    displayArtistaInfo(item) {
      this.viewPerfilArtista = false;
      this.currentItem = item;
      // window.setTimeout(() => {
      this.nombreArtista = this.currentItem.nombre;
      this.perfilArtista = this.currentItem.perfil;
      this.cycle = false;
      // this.imageIndex = this.currentItem.index;
      this.viewPerfilArtista = true;
      this.$refs.fotosSlide.updateInternalValue(this.currentItem.index);
      // window.setTimeout(() => {
      //   this.interval = "500";
      //   this.cycle = true;
      //   this.viewPerfilArtista = false;
      // }, 10000);
      // }, 500);
    },
    changeArtistaTopTres(event, index) {
      this.currentArtistaTopTres = this.artistasTopTresPhotos[index];
      this.activechacal = false;
    },
    graphArtistaTopInfo(item) {
      this.graphActiveArtist = item;
    },
    positionArtistaTopInfo(item) {
      this.positionActiveArtist = item;
    },
    premiosArtistaTopInfo(item) {
      this.premiosActiveArtist = item;
    },
    redesArtistaTopInfo(item) {
      this.redesActiveArtist = item;
    }

    // revealPerfilGrafo() {
    //   return this.$scrollmagic
    //     .scene({
    //       triggerElement: "#topArtistas-grafo",
    //       triggerHook: 0.9,
    //       duration: "100%"
    //     })
    //     .setClassToggle("#perfil-topArtistas-grafo", "perfilVisible");
    // },
    // revealPerfilFotoGrafo() {
    //   return this.$scrollmagic
    //     .scene({
    //       triggerElement: "#topArtistas-grafo",
    //       triggerHook: 0.9,
    //       duration: "100%"
    //     })
    //     .setClassToggle(".perfilFoto-topArtistas-grafo", "visible");
    // },
    // revealPerfilPosicion() {
    //   return this.$scrollmagic
    //     .scene({
    //       triggerElement: "#topArtistas-posicion",
    //       triggerHook: 0.9,
    //       duration: "100%"
    //     })
    //     .setClassToggle("#perfil-topArtistas-posicion", "perfilVisible");
    // },
    // revealPerfilFotoPosicion() {
    //   return this.$scrollmagic
    //     .scene({
    //       triggerElement: "#topArtistas-posicion",
    //       triggerHook: 0.9,
    //       duration: "100%"
    //     })
    //     .setClassToggle(".perfilFoto-topArtistas-posicion", "visible");
    // },
    // revealPerfilPremios() {
    //   return this.$scrollmagic
    //     .scene({
    //       triggerElement: "#topArtistas-premios",
    //       triggerHook: 0.9,
    //       duration: "100%"
    //     })
    //     .setClassToggle("#perfil-topArtistas-premios", "perfilVisible");
    // },
    // revealPerfilFotoPremios() {
    //   return this.$scrollmagic
    //     .scene({
    //       triggerElement: "#topArtistas-premios",
    //       triggerHook: 0.9,
    //       duration: "100%"
    //     })
    //     .setClassToggle(".perfilFoto-topArtistas-premios", "visible");
    // },
    // revealPerfilRedes() {
    //   return this.$scrollmagic
    //     .scene({
    //       triggerElement: "#topArtistas-redes",
    //       triggerHook: 0.9,
    //       duration: "100%"
    //     })
    //     .setClassToggle("#perfil-topArtistas-redes", "perfilVisible");
    // },
    // revealPerfilFotoRedes() {
    //   return this.$scrollmagic
    //     .scene({
    //       triggerElement: "#topArtistas-redes",
    //       triggerHook: 0.9,
    //       duration: "100%"
    //     })
    //     .setClassToggle(".perfilFoto-topArtistas-redes", "visible");
    // }
  }
};
</script>
<style lang="scss">
.artistas-desktop {
  padding: 0;
  max-width: 100vw;
  height: auto;
}

.artistas-desktop-header {
  max-height: 100vh;
}

.artistas-desktop-content {
  background: url("/img/artistas/artistas-textura-bkg.jpg") repeat-y center;
  max-width: 90vw;
  background-size: contain;
  padding-top: 35vw;
  margin: 6vw 5vw 0;
  height: 100%;
}

.artistas-header-text {
  font-family: Segoe;
  background-color: white;
  height: 32vw;
  max-width: 48vw !important;
  margin-left: 8vw;
  padding: 4vw 6vw;
}

.artistas-header-photos {
  height: 100%;
  width: 10vw;
}

.artista-photo-desktop {
  height: 32vw;
}

.artistas-header-title-container {
  margin: 3vw 0 0 5vw;
}

.artistas-header-title {
  color: #7407ec;
  font-size: 1.5vw;
  letter-spacing: 4px;
}

.graficos-artistas {
  max-width: 100vw;
  margin: 0 -5vw;
}

.graficos-section {
  background-color: white;
  width: 100vw;
  height: 100vh;
}

.top-tres-presentation {
  background-color: rgba(0, 0, 0, 0.4);
  width: 65vw;
  margin: 4vw 0;
  position: relative;
  right: 3vw;
}

.artistas-top-tres-container {
  max-width: 115px;
  margin-left: 20vw;
  padding-top: 4vw;
}

#title-artistas-top-tres {
  max-width: 25vw;
  position: relative;
  left: 7.5vw;
  max-height: 410px;
  top: 2vw;
}

.chacal-textd {
  max-width: 25vw;
  position: relative;
  left: 2vw;
  max-height: 500px;
  top: 2vw;
  background-color: black;
  color: #fff;
  overflow: auto;
  font-family: "Andada";
  overflow: auto;
  margin: 20px 0px 10px 10px;
  padding: 30px;
}

.chacal-text::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.chacal-text::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.chacal-text::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.chacal-text::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.top-tres-artistas {
  height: 100vh;
}

#photo-artista-top-tres {
  max-height: 550px;
  max-width: 500px;
}

.top-tres-artistas-main-photo {
  position: relative;
  z-index: 2;
  width: 500px;
  left: 10vw;
}

.photo-artista-top-tres {
  max-width: 115px;
  max-height: 115px;
  margin-bottom: 1vw;
  cursor: pointer;
}

.top-tres-nombre-artista {
  color: #f7d730;
  margin-left: 3vw;
  letter-spacing: 3px;
  font-size: 18px;
}

#top-tres-nombre-artista {
  background-color: black;
  padding: 8px;
  margin-top: -1px;
}

.top-tres-artista-more-info {
  color: white;
  font-style: italic;
  text-decoration: none;
  position: relative;
  left: 12vw;
}

.graficos-top-artistas {
  max-width: 90vw;
  margin: 2vw 4vw 4vw;
  height: 90vh;
}

.bar-black {
  width: 100%;
  height: 30px;
  background-color: black;
}

.artistas-top-carousel .artistas-carousel-wrapper {
  height: 100%;
  // padding-bottom: 10vw;
  .artistas-carousel-inner-wrapper {
    overflow: unset;
  }
}
</style>
