<template>
  <div class="artistas-perfil-info triggerRevealPerfil"
  :class="{'artistasPerfil-paddingRight': paddingRight, 'artistasPerfil-paddingLeft': paddingLeft}">
    <v-layout class="artistasTop-mainInfo">
      <v-img :src="mainImage" class="artistaTop-foto" :class="{'imageSize': imageSize}"></v-img>
      <div class="topArtistas-cancionesPopulares" :class="{'listadoCanciones-position': imageSize}">
        <div class="artista-canciones-populares">
          <span class="title-font text-uppercase" v-for="(cancion, i) in artistaTopData.cancionesPopulares" :key="i">{{ cancion }}
            <v-spacer></v-spacer>
          </span>
        </div>
      </div>
    </v-layout>
    <div :id="idPerfil">
      <perfil-artista class="artistaTop-perfil"
        :nombreArtista="artistaTopData.nombre"
        :perfilArtista="artistaTopData.perfil"
      />
    </div>
    <v-img :src="perfilImage" class="artistaTop-perfil-foto"
    :class="classNamePerfilFoto"
    ></v-img>
  </div>
</template>
<script>
import ArtistaPerfil from './ArtistaPerfil'
export default {
  name: "ArtistasTop",
  components: {
    'perfil-artista': ArtistaPerfil
  },
  props: {
    paddingLeft: {
      type: Boolean,
      default: false
    },
    paddingRight: {
      type: Boolean,
      default: false
    },
    artistaTopData: {
      type: Object
    },
    idPerfil: {
      type: String,
      default: ''
    },
    classNamePerfilFoto: {
      type: String,
      default: ''
    },
    chartType: {
      type: String,
      default: ''
    }
  },
  computed: {
    imageSize () {
      if ((this.artistaTopData.nombre === 'El Chacal' && this.chartType === 'posicion') || this.artistaTopData.nombre === 'Baby Lores'
        || this.artistaTopData.nombre === 'Lobo King Dowa' || this.artistaTopData.nombre === 'Alexander'
        || this.artistaTopData.nombre === 'Randy Malcom' || this.artistaTopData.nombre === 'El Micha' || (this.artistaTopData.nombre === 'Chocolate' && this.chartType === 'posicion')) {
        return true
      } else {
        return false
      }
    },
    mainImage () {
      if (this.artistaTopData.nombre === 'El Chacal') {
        return this.artistaTopData.mainFoto.split('.')[0]+'-'+this.chartType+'.jpg'
      } else if (this.artistaTopData.nombre === 'Chocolate' && (this.chartType === 'posicion' || this.chartType === 'grafo')) {
        return this.artistaTopData.mainFoto.split('.')[0]+'-'+this.chartType+'.jpg'
      } else {
        return this.artistaTopData.mainFoto
      }
    },
    perfilImage () {
      if (this.artistaTopData.nombre === 'El Chacal') {
        return this.artistaTopData.perfilFoto.split('.')[0]+'-'+this.chartType+'.jpg'
      } else if (this.artistaTopData.nombre === 'Chocolate' && (this.chartType === 'posicion' || this.chartType === 'grafo')) {
        return this.artistaTopData.perfilFoto.split('.')[0]+'-'+this.chartType+'.jpg'
      } else {
        return this.artistaTopData.perfilFoto
      }
    }
  },
  data: () => ({
    viewPerfilFoto: false,
    cancionesPopulares: [],
  }),
  mounted () {
    // if (this.artistaTopData !== undefined) {
    //   this.getCancionesPopulares()
    // }
  },
  updated () {
    // if (this.artistaTopData !== undefined) {
    //   this.getCancionesPopulares()
    // }
  },
  methods: {
    getCancionesPopulares () {
      this.cancionesPopulares = []
      this.$store.dispatch('GET_CANCIONES').then(() => {
        for (let c of this.$store.getters.canciones) {
          this.artistaTopData.cancionesPopulares.find((id) => {
            if (id === c.id) {
              this.cancionesPopulares.push(c.songtitle)
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.artistas-perfil-info {
  background-color: white;
  width: 100%;
  height: 100%;
  padding-bottom: 2vw;
}

.topArtistas-cancionesPopulares {
  background-color: rgba(247, 215, 48, 0.8);
  color: black;
  font-size: 11px;
  line-height: 2.5;
  position: relative;
  height: auto;
  min-width: 40%;
  padding: 3vw 1vw 3vw 3vw;
  text-align: right;
  letter-spacing: 3px;
  margin-top: 4vw;
}

.artista-canciones-populares {
  overflow-y: auto;
  height: 100%;
  padding-right: 2vw;
}

.artista-canciones-populares::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.artista-canciones-populares::-webkit-scrollbar-track {
  background: black;
}

/* Handle */
.artista-canciones-populares::-webkit-scrollbar-thumb {
  background: #f7d730;
}

.listadoCanciones-position {
  right: 40%;
}

.artistasPerfil-paddingRight {
  padding-right: 2vw;
}

.artistasPerfil-paddingLeft {
  padding-left: 2vw;
}

.artistaTop-foto {
  height: 100%;
  max-width: 57.5%;
}

.imageSize {
  max-width: 100%;
  width: 100%;
}

.artistasTop-mainInfo {
  height: 100%
}

.artistaTop-perfil {
  width: 30%;
  position: relative;
  bottom: 29vw;
  right: 5vw;
}

.artistaTop-perfil-foto {
  position: relative;
  bottom: 66vw;
  max-width: 35%;
  left: 20.5vw;
  height: 86%;
}

</style>
