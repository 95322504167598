<template>
  <div class="artistas-carousel-wrapper">
    <v-flex v-if="topRanking">
      <v-img src="/img/artistas/top-ranking.png" class="top-ranking-img"></v-img>
      <span class="title-font top-ranking-title">ToP RaNKInG</span>
    </v-flex>
    <v-layout
      row
      class="artistas-carousel-inner-wrapper inner-wrapper-row"
      :class="[carousel_background, {'padding-inner-wrapper': paddingLeftRight}]"
    >
      <v-flex
        md12
        v-for="(item, i) in filteredItems "
        class="artista-el-mb text-xs-center"
        :key="item.id"
        :class="{'avatar-margin': avatar_margin, 'last-element-pb': i === data.length - 1}"
      >
        <v-hover :disabled="disableHover">
          <!-- <div
            class="artista-photo"
            :ref="'artistaAvatar'+i"
            slot-scope="{ hover }"
            :class="[{'avatar-photo-top-ranking-desktop': avatar_size_top_ranking, 'firstActiveColor': (i === 0 && firstActive)}, `elevation-${hover ? 12 : 2}`]"
          > -->
            <v-avatar
              class="avatar-size"
            @click="artistaOnClick(item, i)"
              :class="{'avatar-size-top-ranking': avatar_size_top_ranking}"
            >
              <v-tooltip bottom close-delay=".5s" color="#000" :disabled="disableToolTip">
                <template v-slot:activator="{ on }">
                  <v-img :id="i" class="avatar-photo" :class="{'selected': selected === i}" :src="item.perfilFoto" v-on="on"></v-img>
                </template>
                <span class="text-font">{{ item.nombre }}</span>
              </v-tooltip>
            </v-avatar>
          <!-- </div> -->
        </v-hover>
        <div class="artista-name">
          <span v-if="show_name" class="artista-name-font">{{ item.nombre }}</span>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "PhotoCarousel",
  props: {
    todosArtistas: {
      type: Boolean,
      default: false
    },
    firstActive: {
      type: Boolean,
      default: false
    },
    data: Array,
    disable_hover: {
      type: [Boolean, String],
      default: false
    },
    disable_tooltip: {
      type: [Boolean, String],
      default: false
    },
    carousel_background: String,
    show_name: Boolean,
    avatar_size_top_ranking: Boolean,
    avatar_margin: Boolean,
    color: {
      type: String,
      default: "gold"
    },
    topRanking: {
      type: Boolean,
      default: false
    },
    paddingLeftRight: Boolean
  },
  data() {
    return {
      disable_tooltip_cmp: false,
      disable_hover_cmp: false,
    //   activeElement: null,
    selected: this.firstActive ? 0 : null,
      first_active_element: null
    };
  },
  computed: {
    filteredItems: function() {
      if (this.todosArtistas) {
        return this.data;
      }
      return this.data.slice(0, 5);
    },
    disableToolTip() {
      if (this.disable_tooltip === "true") {
        return true;
      } else {
        return false;
      }
    },
    disableHover() {
      if (this.disable_hover === "true") {
        return true;
      } else {
        return false;
      }
    },
    backgroundColor() {
      if (this.color === "white" || this.color === "gold") {
        return { backgroundColor: this.color };
      }
      return null;
    }
  },
  methods: {
    artistaOnClick(item, index) {
        this.selected = index;
    //   if (!this.todosArtistas) {
    //     if (this.activeElement !== null) {
    //       this.activeElement.style.backgroundColor = "white";
    //     }
    //     if (index !== 0) {
    //       this.$refs["artistaAvatar0"][0].classList.remove("firstActiveColor");
    //     } else {
    //       this.$refs["artistaAvatar0"][0].classList.add("firstActiveColor");
    //     }
    //     let active = this.$refs["artistaAvatar" + index][0];
    //     active.style.backgroundColor = "gold";
    //     this.activeElement = active;
    //   }
      this.$emit("handleAvatarOnClick", item);
    }
  }
};
</script>

<style scoped lang="scss">
.artistas-carousel-wrapper {
  overflow: hidden;
  width: 100vw;
}

.artistas-carousel-inner-wrapper {
  overflow: auto;
  margin-top: 1vh;
  padding: 6vw 4vw;
  /* display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; */
}

.lightgray {
  background-color: rgba(184, 184, 184, 0.7);
}

.black {
  background-color: rgba(53, 53, 53, 0.6) !important;
}

.artistas-carousel-inner-wrapper::-webkit-scrollbar {
  display: none;
}

.artista-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 20vw;
  font-size: 2.5vh;
}

.artista-name-font {
  font-family: "Segoe";
  color: white;
}

.avatar-size {
  height: 50px !important;
  width: 50px !important;
}

.avatar-margin {
  margin: 5px;
}

.avatar-style-top-tres {
  width: 80px !important;
  height: 80px !important;
  margin: 2px;
}

.avatar-size-top-ranking {
  width: 60px !important;
  height: 60px !important;
}

.padding-inner-wrapper {
  padding: 2vh 2vw 4vw 2vw;
}

@media (min-width: 320px) and (max-width: 600px) {
  .artista-photo {
    margin: 2px 8px;
    flex: 0 0 auto;
  }

  .artista-name {
    /*width: 15vw;*/
    font-size: 2vh;
  }
}

@media (min-width: 400px) and (max-width: 600px) {
  .artista-photo {
    margin: 2px 8px;
    flex: 0 0 auto;
  }

  .artista-name {
    width: 10vw;
    font-size: 2vh;
  }
}

.avatar-photo {
    border: 2px solid gold;
    box-sizing: content-box;
    &.selected {
        border-color: white;
    }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 2048px) {
  /* .firstActiveColor {
    background-color: gold !important;
  } */

  .artistas-carousel-inner-wrapper {
    padding: 2vw;
    flex-wrap: wrap;
  }

  .artista-photo {
    margin: 2px 10px;
    width: 70px;
    height: 70px;
    cursor: pointer;
  }

  .avatar-size {
    height: auto !important;
    width: auto !important;
  }

  .avatar-photo {
    height: 65px;
    width: 65px;
  }

  .avatar-photo-top-tres {
    margin: 2px 9px;
  }

  .artistas-carousel-wrapper {
    width: 10.3vw;
    height: 32vw;
    background: url("/img/menu/toolbar-gradient.png");
    background-size: contain;
  }

  .black-bkg-color {
    background: transparent !important;
  }

  .artistas-carousel-inner-wrapper {
    /* overflow-x: hidden!important; */
    /* scrollbar-color: rebeccapurple green; */
    padding-top: 2vh;
    padding-bottom: 0;
    margin-top: 0;
    background-color: transparent;
    max-height: 100%;
  }

  /* .inner-wrapper-row {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: normal!important;
        -ms-flex-direction: column!important;
        flex-direction: column!important;
        height: inherit;
    } */

  /* .last-element-pb {
        padding-bottom: 2vh;
    } */

  .avatar-photo-top-ranking-desktop {
    margin-left: 8px;
    margin-right: 2px;
  }

  .avatar-style-top-tres {
    width: 107px !important;
    height: 107px !important;
    margin-left: 1px !important;
  }

  .padding-inner-wrapper {
    padding: 0;
  }

  .black {
    background-color: transparent !important;
  }

  .artista-el-mb {
    margin-bottom: 12px;
  }

  .artista-name {
    width: auto;
  }

  .top-ranking-img {
    margin: 1vw 0 1vw;
  }

  .top-ranking-title {
    color: black;
    margin: 0 25px;
  }
}
</style>
