<template>
  <v-container class="artistas-layout-wrapper" ref="artistasMobileContainer">
    <div class="artistas-background">
      <v-layout
        column
        sticky-container
        id="artistas-component"
        v-if="!$store.getters.getViewPerfilArtista"
      >
        <v-layout row id="artistas-home" class="artistas-home">
          <!-- <video preload="yes" autoplay loop muted playsinline class="main-video">
            <source src="/img/artistas/artistas-mobile-header.webm" type="video/webm" />
            <source src="/img/artistas/artistas-mobile-header.mp4" type="video/mp4" />
          </video> -->
          <v-img src="/img/artistas/artistas-mobile-header.gif" id="artistas-home-img"></v-img>
          <!-- <v-img src="/img/artistas/artistas-desktop-header.gif" id="artistas-desktop-img"></v-img> -->
        </v-layout>
        <div v-sticky sticky-offset="{top: 64}">
          <CancionesSidebar
            flat
            icon
            class="artistas-sidebar position-sidebar sidebar-transparent"
            view_actions
          ></CancionesSidebar>
        </div>
        <v-layout class="top_artistas view-min-height">
          <v-layout column id="top-artistas-wrapper">
            <div id="top-artistas-description">
              <!-- <div class="text-font inner"> -->
              <p class="text-font" v-html="mobileHeaderText"></p>
              <!-- </div> -->
            </div>
            <PhotoCarousel
              show_name
              v-on:handleAvatarOnClick="displayArtistaInfo"
              todosArtistas
              :data="artistas"
              carousel_background="black"
            />
          </v-layout>
        </v-layout>
        <GraficosComponent
          class="grafo-relaciones"
          chartType="grafo"
          :data="grafoTopFive"
          tituloGrafico="Más colaboraciones, más influencia"
          viewTitle
          viewCarousel
          :text="graficosTextos.grafo"
          :show_text="false"
        >
          <grafo-artistas :movil="true"></grafo-artistas>
        </GraficosComponent>

        <GraficosComponent
          class="first-grafico-posicionamiento"
          :text="textospos.anual"
          tituloGrafico="Para ser popular"
          viewTitle
          :viewCarousel="false"
        >
          <grafico-encuesta chartType="anual" color="#f600c1" />
        </GraficosComponent>
        <GraficosComponent
          class="first-grafico-posicionamiento"
          :text="textospos.mensual"
          tituloGrafico="posicionamiento"
          :viewTitle="false"
          :viewCarousel="false"
        >
          <grafico-encuesta chartType="mensual" color="#0de0c1" />
        </GraficosComponent>
        <GraficosComponent
          class="first-grafico-posicionamiento"
          :text="textospos.manual"
          tituloGrafico="posicionamiento"
          :viewTitle="false"
          :viewCarousel="false"
        >
          <grafico-encuesta chartType="manual" color="#ee0a1b" />
        </GraficosComponent>
        <GraficosComponent
          class="first-grafico-posicionamiento"
          :data="posicionTopFive"
          chartType="posicion"
          :text="textospos.internet"
          tituloGrafico="posicionamiento"
          :viewTitle="false"
          viewCarousel
        >
          <grafico-encuesta chartType="internet" color="#e5dc16" />
        </GraficosComponent>

        <GraficosComponent
          class="grafico-premios"
          :data="premios"
          chartType="premios"
          tituloGrafico="Reconocimientos, pocos"
          viewTitle
          viewCarousel
          :text="graficosTextos.premios"
        >
          <grafico-premios :graficoData="premios" :viewText="false" />
        </GraficosComponent>
        <GraficosComponent
          class="grafico-redes"
          second
          :data="redesTopFive"
          chartType="redes"
          tituloGrafico="En Youtube"
          viewTitle
          viewCarousel
          :text="graficosTextos.redes"
        >
          <grafico-redes :graficoData="redes" :viewText="false" />
        </GraficosComponent>

        <!-- <v-flex class="top-3-artistas view-min-height"> -->
        <v-layout column flat color="transparent" class="artista-photo-layout">
          <div class="top-tres-artista-info">
            <v-img :src="currentArtistaTopTres.mainFoto" class="artista-top-photo"></v-img>
            <v-layout row class="artista-bottom-name-wrapper">
              <div class="artista-bottom-name subtitle-letter-spacing">
                <span class="title-font text-uppercase">{{ currentArtistaTopTres.nombre }}</span>
              </div>
              <a
                :href="getEnlace"
                v-if="getEnlace ==='#chacal'"
                @click="showMoreText = true"
                class="text-body white--text"
              >leer más</a>
              <a v-if="getEnlace !== '#chacal'" rel="noreferrer" target="_blank" class="text-body white--text">leer más</a>
            </v-layout>
          </div>
          <v-layout class="top-tres-carousel-container" id="chacal">
            <v-img
              :src="artistaTopTres.circle"
              class="mobile-photo-artista-top-tres"
              v-for="(artistaTopTres, i) in top_tres_data"
              :key="i"
              @click="showArtistaPhoto($event, i)"
            ></v-img>
          </v-layout>
        </v-layout>

        <div class="chacal-text text-body" v-if="showMoreText">
          <p>
            Ramón Lavado Martínez, El Chacal, en un show, en Cuba, República Dominicana, Estados
            Unidos o Italia, se tira en el piso y perrea reguetón. Tiene unos movimientos de cintura
            sueltos,
            sexuales. Recoge los blúmeres y sujetadores que le lanzan las fanáticas desde el público y se
            los
            restriega por el cuerpo. El Chacal, que nació en el barrio “El Hueco”, en La Lisa, el 8 de
            febrero
            de 1986 y debutó en la música como cantante de hip hop, filma de loco y cae bien.
          </p>
          <p>
            En el año 2003 formó su propio proyecto llamado Los Chavos junto a su colega el Señor
            Rodríguez. Recorrió el país en varias giras nacionales hasta ser llamado por Baby Lores para
            formar parte del prestigioso grupo Clan 537; perteneciente al Catálogo Artístico de la Empresa
            Benny Moré.
          </p>
          <p>
            Cuando todavía no era reconocido se presentó tirándole al Insurrecto —que era el mejor rapero
            del reguetón en esa época— con una canción que decía: “ya se ablandó la yuca/ así que cuál es la
            disputa/ mira que se soltó/ el Chacal, el hijo de puta”. A partir de entonces tuvo todas las
            miradas
            sobre él y comenzó un período llamado popularmente “la tiradera” entre Baby Lores, El Chacal e
            Insurrecto. El Chacal era versátil: cantaba, rapeaba, componía. Junto a Lores disparó Tiradera
            para el Insurrecto (Ahù) en 2008, canción icónica dentro del género urbano cubano.
          </p>

          <p>
            Después de tres producciones discográficas emprendió su carrera como solista firmando con la
            compañía Hitown Entertainment en 2009. Los dos discos que produjo en esta época —Reporte y
            El Clave— tienen colaboraciones con artistas como Haila Mompié, la Charanga Habanera,
            Yulién Oviedo.
          </p>
          <p>
            Luego se unió a Luis Javier Prieto Cedeño y crearon el dúo Chacal y Yakarta. Canciones como
            El tubazo, Besito con lengua, La cañandonga, Diciembre, Sexo y Ellas son locas posicionaron a
            los reguetoneros entre los favoritos del público. En 2015 se vieron envueltos en una polémica
            cuando la empresa norteamericana All Star Promotions los denunció por supuestas acciones
            fraudulentas y estafas. La firma aseguró, a través de un comunicado, que el dúo se separaba
            después de ellos invertir una cuantiosa suma de dinero en sus carreras. A finales de este año
            realizaron sus últimas presentaciones en Estados Unidos y ponen fin al dueto.
          </p>
          <p>
            En 2016, El Chacal despegó a la fama internacional debido al éxito del tema Ay, mi Dios junto a
            Yandel, Pitbull y DJ Chino. Tuvo presentaciones en escenarios como Nuestra Belleza Latina y
            Premios Juventud.
          </p>
          <p>
            También ha incursionado en la bachata. No te vayas, El reloj, Procuro olvidarte son algunos
            temas en los que se nombra “El Chacal de la bachata”. Además, grabó con cantantes reparteros
            como Harryson para el tema La botella y con El Negrito, Kokito, Munu Manu para Maltrataíta y
            loca. Durante los Premios Juventud 2018 dedicados al género urbano de Cuba, se presentó con
            Calentando La Habana, el primer escenario importante donde tocan un tema con pedal
            —característica musical esencial del reparto.
          </p>

          <p>
            Sin embargo, respecto a las colaboraciones cuenta Luismer Nieblas, representante de DJ Unic:
            “una vez, en el estudio Célula Music, le ofrecieron 10 mil CUC en efectivo para que grabara con
            unos muchachos que estaban empezando. El Chacal se negó. Otras veces ha pasado que los
            ῾chamaquitosʼ están grabando, a él le gusta el tema y lo hace”.
          </p>
          <p>
            No obstante, son cuestionados los precios de las entradas para sus conciertos en Cuba. El 25 de
            agosto de 2019, el artista tuvo presentación en el Hotel Las Cuevas, Trinidad, donde el precio
            por
            persona era de 15 CUC y había ofertas que oscilaban entre los 200 CUC y 500 CUC para el
            público VIP.
          </p>
          <p>
            Con la explosiva y extendida trayectoria dentro del género, Chanchi, como lo llaman sus amigos,
            ha logrado un público amplio y fiel y que lo ubican, sin dudas, como el rey del trono; al menos
            en 2018.
          </p>
        </div>
        <!-- </v-flex> -->
      </v-layout>
      <artista-inside v-if="$store.getters.getViewPerfilArtista" />
      <!-- <ListaComentarios comments_header_ml></ListaComentarios> -->
      <!-- <ComentariosForm></ComentariosForm>
            <ComentariosModal v-if="$store.getters.showModal">
                <ComentariosForm></ComentariosForm>
      </ComentariosModal>-->
    </div>
  </v-container>
</template>
<script>
import PhotoCarousel from "../../components/PhotoCarousel";
import GraficosComponent from "../../components/GraficosComponent";
// import ComentariosForm from '../../components/Comentarios/ComentariosForm'
// import ListaComentarios from '../../components/Comentarios/ListaComentarios'
// import ComentariosModal from '../../components/Comentarios/ComentariosModal'
// import AppFooter from '../../components/Home/AppFooter'
import CancionesSidebar from "../../components/Canciones/CancionesSidebar";
import GraficoPosicionamientoMobile from "./GraficoPosicionamientoMobile";
import GraficoPremios from "./GraficoPremios";
import GraficoRedes from "./GraficoRedes";
import ArtistaInsideComponent from "./ArtistaInsideComponent";
import GrafoRelaciones from "./GrafoRelaciones";

export default {
  name: "",
  props: {
    mobileHeaderText: {
      type: String,
      default: ""
    },
    graficosTextos: {
      type: Object
    },
    datosArtistas: {
      type: [Object, Array]
    }
  },
  data() {
    return {
        showMoreText: false,
      show_nombre_artista: false,
      textospos: {
        anual: `
                <p>Mientras más famosa es una canción, más reconocido es el artista. Por eso, para saber cuándo una canción es popular los especialistas y los medios de comunicación elaboran listas de éxitos, con frecuencia semanal, mensual o anual; y sobre todo miden por cuánto tiempo están en el top.</p>
                <p>Para realizar el ranking anual se calculó la posición de cada una de las canciones según el top 100 anual del sitio Pista Cubana y el Top Música Urbana y reguetón – National AirPlay Cuba y a cada autor se le suman los puntos de cada una de sus canciones.</p>
                <p>Alexander y Randy Malcom de Gente de Zona están en los primeros lugares, el primero estuvo 23 veces en listas con 18 canciones y el segundo 21 veces en lista con 16. Los temas Sarte del satén y Otra noche más, de Alexander con Jacob Forever y el Cata hacen la diferencia.  La canción Bailando estuvo en primer lugar de los años 2013 y 2014. </p>
                <p>La tercera posición la ocupa Jacob Forever, que luego de su salida de Gente de Zona en marzo de 2013, continuó pegando temas y estuvo 14 veces en listas, su tema más popular Hasta que se seque el Malecón estuvo en el segundo lugar de 2015.</p>`,
        mensual: `
                <p>El top mensual se realizó a partir de las listas de Adriano DJ en la revista Vistar y del youtuber Adrián Fernández y Abdel la Esencia. En este caso se divide uno entre cada posición mensual por canción, de las listas, donde los más cercanos a la primera obtienen las mejores puntuaciones. El resultado se divide entre doce para que los rangos sean menores a los anuales y poder sumar todos los valores.</p>
                <p>El Chacal aparece 64 veces en lista y 6 veces ocupa el primer lugar con canciones distintas.</p>
                <p>Sin embargo, este método se puede parcializar e incluir o excluir a un artista, según la opinión de quien elabore la lista y sus gustos personales e intereses. Entonces, preguntar directamente a las personas es importante.</p>`,
        manual: `
                <p>Por eso tuvimos en cuenta la opinión de 53 personas en La Habana quienes listaron las 10 mejores canciones durante el período para ellas. En total se mencionaron 93 artistas, 54 de reguetón y 39 de reparto, que adquiere mayor protagonismo. Chocolate en el medio de los mundos domina esta lista con 39 canciones.</p>
                <p>Este gráfico recoge la elección de las mejores 10 canciones a partir de una encuesta a 53 personas en
                    La Habana.</p>`,
        internet: `
                <p>Además, están los resultados de una encuesta web en la que participaron 329 personas a partir de una lista previamente elaborada de la conjunción de las anteriores. En este caso se dividió 1 entre 65 que fue el mayor número de votos alcanzados.</p>
                <p>Las canciones más votadas fueron: El Chupi Chupi (65), Bajanda (58), El oro es mío (55), Aceite y agua (54) y el Campismo (52). De las cinco primeras Chocolate canta 4, sin embargo, el mejor posicionado es el Chacal porque tiene más canciones (108) con mejores puntuaciones en total.</p>
                <p>Entre los 10 primeros de las listas de éxitos y de las encuestas hay diferencias. En las listas el reparto casi no se incluye y en las encuestas sí. Alexander y Randy Malcom tiene las primeras posiciones en las listas y Chocolate en las encuestas. Aunque en general la mejor posición entre todos los indicadores la tiene El Chacal, quien ha estado en las listas 81 veces con 33 canciones y, además, salió de segundo en las encuestas. </p>
                <p>El top final de posición se calculó a partir de la mayor cantidad de veces repetidas de un artista en las mejores posiciones.</p>`
      },
      top_tres_data: [
        {
          nombre: "El Chacal",
          mainFoto: "/img/artistas/top-tres/chacal.png",
          circle: "/img/artistas/top-tres/chacal-circulo.png",
          enlace: "#chacal",
          text: ""
        },
        {
          nombre: "Alexander",
          mainFoto: "/img/artistas/top-tres/alexander.png",
          circle: "/img/artistas/top-tres/alexander-circulo.png",
          enlace:
            "http://www.granma.cu/cultura/2018-02-27/el-sonido-de-una-epoca-27-02-2018-23-02-59"
        },
        {
          nombre: "Chocolate",
          mainFoto: "/img/artistas/top-tres/chocolate.png",
          circle: "/img/artistas/top-tres/chocolate-circulo.png",
          enlace: "https://www.magazineampm.com/el-evangelio-segun-san-choco/"
        }
      ],
      currentArtistaTopTres: {},
      viewPerfilArtista: false
    };
  },
  mounted() {
    this.currentArtistaTopTres = this.top_tres_data[0];
  },
  computed: {
    artistas() {
      return this.datosArtistas.artistas;
    },
    grafoTopFive() {
      const { grafo } = this.datosArtistas;
      return grafo ? grafo.topFive : [];
    },
    posicionTopFive() {
      const { posicion } = this.datosArtistas;
      return posicion ? posicion.topFive : [];
    },
    premios() {
      return this.datosArtistas.premios || [];
    },
    redes() {
      const { redes } = this.datosArtistas;
      return redes ? redes.general : {};
    },
    redesTopFive() {
      const { redes } = this.datosArtistas;
      return redes ? redes.topFive : [];
    },
    bkgColor: {
      get: function() {
        return this.bkg_color;
      },
      set: function(value) {
        this.bkg_color = value;
      }
    },
    photo_src_val: {
      get: function() {
        if (this.photo_src === "") return this.topTresData[0].src;
        else return this.photo_src;
      },
      set: function(value) {
        this.photo_src = value;
      }
    },
    artista_name_val: {
      get: function() {
        if (this.artista_name === "") return this.topTresData[0].nombre;
        else return this.artista_name;
      },
      set: function(value) {
        this.artista_name = value;
      }
    },
    topTresData() {
      return this.top_tres_data;
    },
    getEnlace() {
      if (this.currentArtistaTopTres !== "#chacal") {
        return this.currentArtistaTopTres.enlace;
      } else {
        return "#chacal";
      }
    }
  },
  methods: {
    // setData(value) {
    //     this.artistasData.push(value)
    // },
    displayArtistaInfo: function(item) {
      this.$store.commit("setViewPerfilArtista", true);
      this.$store.commit("setCurrentArtista", item);
    },
    showArtistaPhoto: function(event, index) {
      this.currentArtistaTopTres = this.top_tres_data[index];
    }
  },
  components: {
    PhotoCarousel,
    GraficosComponent,
    // ComentariosForm,
    // ListaComentarios,
    // ComentariosModal,
    // AppFooter,
    CancionesSidebar,
    "grafico-encuesta": GraficoPosicionamientoMobile,
    "grafico-premios": GraficoPremios,
    "grafico-redes": GraficoRedes,
    "artista-inside": ArtistaInsideComponent,
    "grafo-artistas": GrafoRelaciones
  }
};
</script>

<style scoped>
/* #artistas-home-img {
  width: 85vw;
} */

.artistas-sidebar {
  z-index: 2;
  width: 15vw;
  position: absolute;
  right: 0;
  /* margin-top: 10vw; */
  /* position: sticky !important; */
  /* height: 30vw; */
  /* background-color: transparent !important; */
}

#artistas-desktop-img {
  display: none;
}

.artistas-layout-wrapper {
  padding: 0;
  width: 100vw;
}

.artistas-background {
  background: url("/img/artistas/artistas-textura-bkg.JPG") repeat;
  background-size: contain;
  width: 100vw;
}

.top_artistas {
  margin-bottom: 3vh;
}

.top-tres-title {
  letter-spacing: 2vw;
  font-weight: 900;
  font-family: "Andada" !important;
  font-size: 5vw;
}

#artistas-component {
  background-color: transparent;
}

#artistas-home-title-container {
  margin-right: -10vw;
  background: black;
  color: white;
}

.subtitle-letter-spacing {
  letter-spacing: 2px;
}

#artistas-home-title {
  font-size: 6vw !important;
}

#artistas-home-description-text {
  background: lightgray;
}

#top-artistas-wrapper {
  background: transparent;
}

#top-artistas-description {
  background-color: rgba(229, 220, 22, 0.85);
  width: 86vw;
  height: 100%;
  font-size: 14px;
  letter-spacing: 1px;
  padding: 10vw 10vw 8vw;
}

#inner-wrapper {
  margin: 12vh 0 0 6vh;
}

/* .artista-top-photo {
        max-width: 315px
    } */

.top-artistas-inner-title {
  font-size: 3vh;
  color: mediumpurple;
  font-family: "Andada-Bold", "Roboto Slaf", serif !important;
}

.top-tres-artista-info {
  margin-top: 20px;
}

.top-tres-carousel-container {
  background-color: rgba(53, 53, 53, 0.6);
  position: relative;
  padding: 10px 0;
  margin: 20px 0 20px;
}

.top-tres-header {
  padding: 4vh 0 3vh 9.5vw;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

.artista-photo-wrapper {
  margin: 9vw 30px 0 30px;
}

.artista-bottom-name-wrapper {
  background-color: black;
  border-radius: 0 !important;
  /* width: 318px; */
  padding: 1vh 16px 2vh 16px;
  margin-left: -1vw;
}

.artista-bottom-name {
  font-size: 4vw;
  color: gold;
  width: 60vw;
  letter-spacing: 5px;
}

.leer-mas-link {
  color: white;
  font-family: Andada-Italic, "Roboto Slaff", serif !important;
  max-height: 2vh;
  margin-top: 0.5vh;
}

.mobile-photo-artista-top-tres {
  max-width: 130px;
  margin: 0 4px;
}

.chacal-text {
  padding: 20px;
  background-color: black;
  color: white;
  height: 100%;
}

@media only screen and (min-device-width: 0px) and (max-device-width: 320px) {
  .artistas-layout-wrapper {
    height: auto;
  }
}
</style>
