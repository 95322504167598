<template>
    <v-flex class="graficos view-min-height">
        <v-layout column id="grafico-wrapper" color="transparent">
            <v-card-title class="graficos-header graficos-text-box" v-if="viewTitle">
                <div class="graficos-title-wrapper">
                    <span class="title-font text-uppercase">{{ tituloGrafico }}</span>
                </div>
            </v-card-title>
            <div class="grafico-container">
                <slot></slot>
            </div>
            <div class="graficos-description-wrapper graficos-text-box" v-if="show_text">
                <p class="text-body" v-html="text"></p>
            </div>
            <div class="grafico-bottom-corousel" v-if="viewCarousel">
                <span class="carousel-headline title-font text-uppercase graficos-bottom-title">top ranking</span>
                <v-layout row class="desktop-top-ranking-container">
                    <div class="top-ranking-left-img" :class="{'box-change-position-on-desktop': second}">

                    </div>
                    <PhotoCarousel show_name class="carousel-graficos" carousel_background='black'
                                   :data="data"
                                   :class="{'carousel-change-position-on-desktop': second}"
                                   avatar_size_top_ranking v-on:handleAvatarOnClick="displayArtistaInfo"/>
                </v-layout>
            </div>
        </v-layout>
    </v-flex>
</template>

<script>
    import PhotoCarousel from '../components/PhotoCarousel'

    export default {
        name: "GraficosComponent",
        components: {
            PhotoCarousel
        },
        props: {
            second: Boolean,
            data: {
              type: [Object, Array]
            },
            tituloGrafico: {
              type: String,
              default: ''
            },
            viewTitle: {
              type: Boolean,
              default: false
            },
            viewCarousel: {
              type: Boolean,
              default: false
            },
            text: {
              type: String,
              default: ''
            },
            chartType: {
              type: String,
              default: ''
            },
            show_text: {
                type: Boolean,
                default: true,
            }
        },
        data () {
            return {
                show_nombre_artista: false
            }
        },
        methods: {
            displayArtistaInfo: function (item) {
                // console.log(item)
                let mainFoto = ''
                if (item.nombre === 'El Chacal') {
                  mainFoto = item.mainFoto.split('.')[0]+'-'+this.chartType+'.jpg'
                  item.mainFoto = mainFoto
                } else if (item.nombre === 'Chocolate' && (this.chartType === 'posicion' || this.chartType === 'grafo')) {
                  mainFoto = item.mainFoto.split('.')[0]+'-'+this.chartType+'.jpg'
                  item.mainFoto = mainFoto
                }
                // let perfilFoto = ''
                // if (item.nombre === 'El Chacal') {
                //   perfilFoto = item.perfilFoto.split('.')[0]+'-'+this.chartType+'.jpg'
                // } else if (item.nombre === 'Chocolate' && (this.chartType === 'posicion' || this.chartType === 'grafo')) {
                //   perfilFoto = item.perfilFoto.split('.')[0]+'-'+this.chartType+'.jpg'
                // }

                // console.log(item)
                this.$store.commit('setViewPerfilArtista', true)
                this.$store.commit('setCurrentArtista', item)
            }
        }
    }
</script>

<style scoped>
    .graficos {
        margin-bottom: 3vh;
    }

    .graficos-header {
        font-size: 6vw;
        padding: 8px 25px 2px 25px;
        letter-spacing: 2px;
    }

    .graficos-title-wrapper {
        width: 70vw;
    }

    .graficos-description-wrapper {
        padding: 10vw 10vw 8vw;
        letter-spacing: 1px;
    }

    .carousel-graficos {
        margin-top: 0!important;
        margin-bottom: 0;
    }

    .grafico-bottom-corousel {
        text-align: center;
        padding-top: 1vh;
    }

    .carousel-headline {
        font-size: 6vw;
        letter-spacing: 2px;
        margin-right: 17vw;
        color: white;
    }

    .grafico-img {
        background-color: lightgrey;
        height: 40vh;
    }

    .top-ranking-left-img {
        display: none;
    }
</style>
