<template>
  <v-layout row class="title-row">
    <v-flex offset-xs1>
      <div
        class="title-container px-4 py-3 black text-uppercase white--text title-font"
      >{{ getChartTitle }}</div>
    </v-flex>
  </v-layout>
</template>
<script>
export default {
  name: "",
  props: {
    tipo: {
      type: String,
      default: ""
    }
  },
  computed: {
    getChartTitle() {
      if (this.tipo === "relaciones") {
        return "Más colaboraciones, más influencia";
      } else if (this.tipo === "posicionamiento") {
        return "Para ser popular";
      } else if (this.tipo === "premios") {
        return "Reconocimientos, pocos";
      } else return "En Youtube";
    }
  }
};
</script>
<style lang="scss">
// .grafico-title {
//   background-color: black;
//   color: white;
//   font-size: 18px;
//   width: 20vw;
//   padding: 12px 0 8px 16px;
// }
.title-row {
  border-bottom: 10px solid black;
}

.title-container {
  display: inline-block;
  line-height: 1;
  letter-spacing: 2px;
  font-size: 16px;
}
</style>
