<template>
    <v-layout class="artista-inside-layout-wrapper">
        <v-flex id="artista-inside-flex-wrapper">
            <v-img :src="$store.getters.getCurrentArtista.mainFoto" id="artista-inside-content" v-touch="{up: showListaCanciones}">
                <v-layout column fill-height>
                <v-flex id="artista-info">
                  <v-expand-transition>
                    <artista-perfil class="artistaTop-perfil" v-if="show_artista_perfil"
                      :nombreArtista="$store.getters.getCurrentArtista.nombre"
                      :perfilArtista="$store.getters.getCurrentArtista.perfil"
                    />
                  </v-expand-transition>
                  <v-expand-transition>
                    <div class="artista-info" v-if="show_artista_canciones">
                      <v-img :src="$store.getters.getCurrentArtista.perfilFoto" id="artista-photo" v-touch="{up: () => showListaCanciones()}">
                          <v-expand-transition>
                              <div id="artista-canciones-info" class="px-4 py-5 artista-canciones-info">
                                  <div id="artista-listado-canciones-headline" class="artista-font-right">
                                      <span class="text-uppercase text-font font-italic">canciones</span>
                                  </div>
                                  <div id="artista-lista-canciones" class="text-font artista-font-right">
                                  <span v-for="(cancion, n) in $store.getters.getCurrentArtista.cancionesPopulares" :key="n" class="text-font artista-font-right font-italic">
                                      {{ cancion }}
                                      <v-spacer></v-spacer>
                                  </span>
                                  </div>
                              </div>
                          </v-expand-transition>
                      </v-img>
                    </div>
                  </v-expand-transition>
                </v-flex>
                <v-flex shrink class="artistas-actions-container">
                  <v-layout id="artistas-actions">
                    <v-btn icon color="transparent" large id="artistas-go-back-btn" @click="goPrevView">
                        <v-icon color="white" medium>arrow_back_ios</v-icon>
                    </v-btn>
                    <!-- <div id="brands-lks-wrapper">
                        <v-btn outline dark class="brands-btn">
                            <icon-base view-box="0 0 576 512" icon-color="white" icon-name="fa-youtube"><icon-youtube /></icon-base>
                        </v-btn>
                        <v-btn outline dark class="brands-btn" style="margin-left: -2.5vw!important">
                            <icon-base view-box="0 0 320 512" icon-color="white" icon-name="fa-facebook"><icon-facebook /></icon-base>
                        </v-btn>
                        <v-btn outline dark class="brands-btn" style="margin-left: -2.5vw!important">
                            <icon-base view-box="0 0 448 512" icon-color="white" icon-name="fa-instagram"><icon-instagram/></icon-base>
                        </v-btn>
                    </div> -->
                  </v-layout>
                </v-flex>
                </v-layout>
            </v-img>
        </v-flex>
    </v-layout>
</template>

<script>
    // import IconBase from '../../components/icons/IconBase';
    // import IconFacebook from '../../components/icons/IconFacebook';
    // import IconYoutube from '../../components/icons/IconYoutube';
    // import IconInstagram from '../../components/icons/IconInstagram';
    import ArtistaPerfil from './ArtistaPerfil'

    export default {
        name: "ArtistaInside",
        components: {
            // IconBase,
            // IconFacebook,
            // IconYoutube,
            // IconInstagram,
            'artista-perfil': ArtistaPerfil
        },
        data () {
            return {
                // show_artista_photo: true,
                show_artista_canciones: false,
                show_artista_perfil: true,
                artista_redes_sociales: [],
                artistaInside: {}
            }
        },
        mounted () {
          this.$vuetify.goTo("#artista-inside-flex-wrapper", {duration: 0});
          this.artistaInside = this.$store.getters.getCurrentArtista
        },
        methods: {
            // showPerfil () {
            // //   console.log("touch")
            //   this.show_artista_perfil = true
            // },
            // showPerfilFoto () {
            // //   console.log("touch")
            //   this.show_artista_photo = true;
            // },
            showListaCanciones () {
            //   console.log("touch")
                this.show_artista_canciones = true;
                this.show_artista_perfil = false;
            },
            goPrevView () {
                this.$store.commit('setViewPerfilArtista', false)
            },
        }
    }
</script>

<style scoped>
    .artistas-actions-container {
      /* height: 25vw; */
      background-color: rgba(0, 0, 0, 0.5);
      /* padding-top: 6vh; */
      /* position: absolute; */
      /* bottom: 0; */
    }

    .artista-inside-layout-wrapper {
        height: 100%;
        margin-bottom: 25vw;
    }

    #artista-inside-content {
        /* padding-top: 10vw; */
        /* margin-top: 18vw; */
        height: 100%;
    }

    .artista-info-text {
        max-height: 100vh;
    }

    #artista-info {
        margin-left: 10vw;
        margin-top: 10vw;
        margin-bottom: 3vw;
        /* border-radius: 0!important;
        height: 70vh;
        width: 100vw;
        padding: 0!important; */
    }

    .box-sizes {
        height: inherit;
        width: inherit;
    }

    /* .artista-info {
      position: absolute;
      top: 0;
      margin-top: 10vw;
    } */

    /* #artista-photo {
        height: 70vh;
    } */

    #artista-info-text {
        background-color: #399eb0;
        opacity: 0.7;
        color: white;
        padding: 20vw 25vw 0 5vw;
    }

    #artista-canciones-info {
        background-color: #f7d730;
        opacity: 0.7;
        /* width: 100vw; */
        /* height: 70vh; */
        color: black;
        /* padding-top: 15vw; */
        /* padding-right: 28vw; */
    }

    #artista-listado-canciones-headline {
        margin-bottom: 5vw;
    }

    .artista-font-right {
        text-align: right;
    }

    .actions-black-line {
        background-color: black;
        opacity: 0.5;
        height: 4.2vh;
        margin-top: -19vw;
    }

    #artistas-actions-wrapper {
        margin-top: 12.5vh;
    }

    /* #artistas-actions {
        background-color: gray;
        width: 100vw;
        height: 8vh;
    } */

    /* #artistas-go-back-btn {
      position: relative;
      bottom: 2vw;
      right: 10px;
    } */

    #artistas-action-btns {
        margin-top: -5vw;
    }

    #brands-lks-wrapper {
        padding: 16px;
        position: relative;
        left: 35%;
        bottom: 35px;
    }

    .brands-btn {
      min-width: 50px;
      height: 55px;
      padding: 10px!important;
      border-radius: 0;
    }

    .slide-fade-enter-active {
        transition: all .5s ease;
    }
    .slide-fade-leave-active {
        transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to {
        transform: translateX(10px);
        opacity: 0.5;
    }

    .slide-fade-photo-enter-active {
        transition: all .5s ease-in;
    }
    .slide-fade-photo-leave-active {
        transition: all .3s ease-out;
    }
    .slide-fade-photo-enter {
        transform: translateX(10px);
        opacity: 0.5;
    }

    @media only screen and (max-device-width: 360px) and (max-device-width: 740px) {
        .artista-inside-layout-wrapper {
            /* height: 100vh; */
            margin: 0;
        }
    }

</style>
