<template>
    <v-layout class="grafico-redes-container">
        <v-layout column class="chart-container">
            <highcharts :options="chartRedes"></highcharts>
                <v-radio-group v-on:change="setSerieData" class="serie-selection-control" v-model="seriestype" row>
                    <v-radio class="choose-leyenda"
                            label="Reproducciones"
                            color="#7d0ef2"
                            value="views"
                    ></v-radio>
                    <v-radio class="choose-leyenda"
                            label="Me gusta"
                            color="#7d0ef2"
                            value="likes"
                    ></v-radio>
                    <v-radio class="choose-leyenda"
                            label="No me gusta"
                            color="#7d0ef2"
                            value="dislikes"
                    ></v-radio>
                </v-radio-group>
<!--                </v-flex>-->
<!--            </v-layout>-->
            <v-flex class="chart-controls">


                <v-slider
                        v-on:change="setSerieData"
                        v-model="year"
                        color="#7d0ef2"
                        label="Año"
                        :max="2018"
                        :min="2010"
                        :thumb-size="36"
                        thumb-label="always"
                ></v-slider>
            </v-flex>
        </v-layout>
        <div class="chart-text" v-if="viewText">
            <p v-html="redes"></p>
        </div>
    </v-layout>
</template>

<script>
    let Highcharts = require('highcharts');
    import {Chart} from 'highcharts-vue';
    import boost from 'highcharts/modules/boost'

    require('highcharts/modules/exporting')(Highcharts);
    require('highcharts/highcharts-more.js')(Highcharts);
    boost(Highcharts)

    export default {
        name: "",
        components: {
            highcharts: Chart
        },
        data: () => ({
            chartRedes: {},
            artist: {
                'views': {
                    "2010": [{name: 'reparto', data: [], color:'#0de0c1'}, {name: 'regueton', data: [], color:'#f600c1'}],
                    "2011": [{name: 'reparto', data: [], color:'#0de0c1'}, {name: 'regueton', data: [], color:'#f600c1'}],
                    "2012": [{name: 'reparto', data: [], color:'#0de0c1'}, {name: 'regueton', data: [], color:'#f600c1'}],
                    "2013": [{name: 'reparto', data: [], color:'#0de0c1'}, {name: 'regueton', data: [], color:'#f600c1'}],
                    "2014": [{name: 'reparto', data: [], color:'#0de0c1'}, {name: 'regueton', data: [], color:'#f600c1'}],
                    "2015": [{name: 'reparto', data: [], color:'#0de0c1'}, {name: 'regueton', data: [], color:'#f600c1'}],
                    "2016": [{name: 'reparto', data: [], color:'#0de0c1'}, {name: 'regueton', data: [], color:'#f600c1'}],
                    "2017": [{name: 'reparto', data: [], color:'#0de0c1'}, {name: 'regueton', data: [], color:'#f600c1'}],
                    "2018": [{name: 'reparto', data: [], color:'#0de0c1'}, {name: 'regueton', data: [], color:'#f600c1'}]
                },
                'dislikes': {
                    "2010": [{name: 'reparto', data: [], color:'#0de0c1'}, {name: 'regueton', data: [], color:'#f600c1'}],
                    "2011": [{name: 'reparto', data: [], color:'#0de0c1'}, {name: 'regueton', data: [], color:'#f600c1'}],
                    "2012": [{name: 'reparto', data: [], color:'#0de0c1'}, {name: 'regueton', data: [], color:'#f600c1'}],
                    "2013": [{name: 'reparto', data: [], color:'#0de0c1'}, {name: 'regueton', data: [], color:'#f600c1'}],
                    "2014": [{name: 'reparto', data: [], color:'#0de0c1'}, {name: 'regueton', data: [], color:'#f600c1'}],
                    "2015": [{name: 'reparto', data: [], color:'#0de0c1'}, {name: 'regueton', data: [], color:'#f600c1'}],
                    "2016": [{name: 'reparto', data: [], color:'#0de0c1'}, {name: 'regueton', data: [], color:'#f600c1'}],
                    "2017": [{name: 'reparto', data: [], color:'#0de0c1'}, {name: 'regueton', data: [], color:'#f600c1'}],
                    "2018": [{name: 'reparto', data: [], color:'#0de0c1'}, {name: 'regueton', data: [], color:'#f600c1'}]
                },
                'likes': {
                    "2010": [{name: 'reparto', data: [], color:'#0de0c1'}, {name: 'regueton', data: [], color:'#f600c1'}],
                    "2011": [{name: 'reparto', data: [], color:'#0de0c1'}, {name: 'regueton', data: [], color:'#f600c1'}],
                    "2012": [{name: 'reparto', data: [], color:'#0de0c1'}, {name: 'regueton', data: [], color:'#f600c1'}],
                    "2013": [{name: 'reparto', data: [], color:'#0de0c1'}, {name: 'regueton', data: [], color:'#f600c1'}],
                    "2014": [{name: 'reparto', data: [], color:'#0de0c1'}, {name: 'regueton', data: [], color:'#f600c1'}],
                    "2015": [{name: 'reparto', data: [], color:'#0de0c1'}, {name: 'regueton', data: [], color:'#f600c1'}],
                    "2016": [{name: 'reparto', data: [], color:'#0de0c1'}, {name: 'regueton', data: [], color:'#f600c1'}],
                    "2017": [{name: 'reparto', data: [], color:'#0de0c1'}, {name: 'regueton', data: [], color:'#f600c1'}],
                    "2018": [{name: 'reparto', data: [], color:'#0de0c1'}, {name: 'regueton', data: [], color:'#f600c1'}]
                },
            },
            year: 2010,
            seriestype: 'views',
            years: ["2010", "2011", "2012", "2013", "2014", "2015", "2016", "2017", "2018"],
            redes: 'Cada año es distinto, el acceso a Internet en Cuba ha aumentado progresivamente y cada vez más artistas apuestan por estar en el escenario virtual, lugar al que van llegando también los usuarios. Youtube es el mejor ambiente para consumir los videos clip.<br><br>' +
                'Con datos obtenidos en mayo de 2019, analizamos la cantidad de vistas, likes, dislikes y comentarios por canción. Y miramos al artista, según la puntuación de cada una de sus canciones.<br><br>' +
                'Mientras más actual es la canción más posibilidades tiene de aumentar sus estadísticas, aunque algunas canciones que llevan más tiempo van acumulando estadísticas de a poco.'
        }),
        props: {
            graficoData: {
                type: Object
            },
            viewText: {
                type: Boolean,
                default: true
            }
        },
        mounted() {
            // let vm = this
            this.chartRedes = {
                chart: {
                    type: 'packedbubble',
                },
                title: {
                    text: ''
                },
                tooltip: {
                    useHTML: true,
                    pointFormat: '<b>{point.name}:</b> {point.value}'
                },
                credits: {
                    enabled: false
                },
                legend: {
                    enabled: true,
                    align: 'right'
                },
                exporting: {
                    enabled: false
                },
                plotOptions: {
                    packedbubble: {
                        minSize: '20%',
                        maxSize: '170%',
                        zMax: 5000000,
                        layoutAlgorithm: {
                            splitSeries: false,
                            gravitationalConstant: 0
                        },
                        dataLabels: {
                            enabled: true,
                            format: '{point.name}',
                            style: {
                                color: 'black',
                                font: 'Andada',
                                textOutline: 'none',
                                fontWeight: 'normal'
                            }
                        }
                    }
                },
                series: this.artist[this.seriestype][this.year]

            }
        },
        computed: {
        },
        watch: {
            graficoData(value) {
                this.loadData(value);
            }
        },
        methods: {
            loadData(chartData) {
                if (!chartData) return;
                // let yearIndex = 0
                for (let year of this.years) {
                    let data = chartData[year]
                    if (!data) return;
                    for (let artist of data) {
                        let pos = (artist.genero == 'reparto') ? 0 : 1
                        this.artist['views'][year][pos].data.push({name: artist.nombre, value: artist['views_yt']})
                        this.artist['dislikes'][year][pos].data.push({name: artist.nombre, value: artist['dislikes_yt']})
                        this.artist['likes'][year][pos].data.push({name: artist.nombre, value: artist['likes_yt']})
                    }
                    // yearIndex++
                }
            },
            setSerieData() {
                switch (this.seriestype) {
                    case 'views':{
                        this.chartRedes.plotOptions.packedbubble.minSize = '20%'
                        break;
                    }
                    case 'likes':{
                        this.chartRedes.plotOptions.packedbubble.minSize = '50%'
                        this.chartRedes.plotOptions.packedbubble.zMin = 10
                        this.chartRedes.plotOptions.packedbubble.zMax = 17000
                        break;
                    }
                    case 'dislikes':{
                        this.chartRedes.plotOptions.packedbubble.minSize = '50%'
                        this.chartRedes.plotOptions.packedbubble.zMin = 10
                        this.chartRedes.plotOptions.packedbubble.zMax = 17000
                        break;
                    }
                }
                if (this.year < 2016 && this.seriestype == 'views'){
                    this.chartRedes.plotOptions.packedbubble.zMax = 10000000
                } else if(this.seriestype == 'views') {
                    this.chartRedes.plotOptions.packedbubble.zMax = 90000000
                }

                this.chartRedes.series = this.artist[this.seriestype][this.year]
            },
        }
    }
</script>
<style>
    .serie-selection-control label {
        font-size: 12px !important;
    }
</style>
<style lang="scss" scoped>
    .grafico-redes-container {
        background-color: white;
    }

    .chart-container {
        width: 60vw;
        padding: 4vw 16px 4vw 0;
    }

    .dot {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 5px;
    }
    .bottom-leyenda{
        text-align: center;
    }

    .bottom-leyenda ul {
        display: inline-block;
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    .bottom-leyenda li {
        float: left;
        padding: 14px 16px;
        text-decoration: none;
        font-family: "Andada";
        font-size: 12px;
        text-transform: uppercase;
    }



    .chart-text {
        width: 35vw;
        padding: 5vw;
        font-family: "Andada";
        font-size: 16px;
        font-weight: 400;
    }

    .serie-selection-control {
        float: left;
        margin-top: -41px !important;
        margin-left: 20px;
        margin-bottom: 20px;
        text-transform: uppercase;
        font-family: "Andada";
    }

    .category-artistas-control {
        width: 35vw;
        margin-left: 15vw
    }

    @media only screen and (min-device-width: 0px) and (max-device-width: 740px) {
        .category-artistas-control {
            margin: 0 6vw;
            padding-right: 10vw;
            width: 100%;
        }
        .serie-selection-control {
            float: left;
            margin-top: 0px !important;
        }
    }
</style>
