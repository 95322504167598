<template>
    <v-layout row class="grafico-premios-container" :class="{'full-row': movil}">
        <v-flex :xs8="!movil" class="cell-map" :class="{'cell-map-movil': movil}">
            <highcharts :options="chartPremios" id="chart-premios"></highcharts>
        </v-flex>
        <div v-if="movil" class="detalles-movil" v-show="selected" :class="{'back-inactive': !selected}">
            <v-layout row justify-space-between>
                <v-flex xs2>
                    <span v-if="!selected" class="title-detalles text-left">Detalles</span>
                    <span v-else class="title-detalles text-left">{{selectedArt.nombre}}</span>
                </v-flex>
                <v-flex xs2>
                    <v-btn icon dark class="align-content-end" @click="closeDetail">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-flex>
            </v-layout>
            <div v-if="selected" class="text-detalles-movil">
                <div v-if="selected" class="text-detalles">
                    <div class="award" v-for="(award, i) in selectedArt.awards" :key="i">
                        <span>{{award.year}}</span> - <span>{{award.categoria}}</span> -  <span>{{award.premio}}</span>
                        <div><b>Resultado: </b>{{award.resultado}}</div>
                        <div>{{award.tipo}}</div>
                    </div>
                </div>
            </div>
        </div>
        <v-flex xs4 v-if="!movil" class="cell-data">
            <div class="leyenda" :class="{'back-inactive': selected}">
                <div v-if="selected" class="title-leyenda">Información</div>
                <div v-show="!selected" class="text-body text-leyenda" v-html="texto">
                </div>
            </div>
            <div class="detalles" :class="{'back-inactive': !selected}">
                <v-layout row justify-space-between>
                    <v-flex xs8>
                        <span v-if="!selected"  class="title-detalles text-left">Detalles</span>
                        <span v-else  class="title-detalles text-left">{{selectedArt.nombre}}</span>
                    </v-flex>
                    <v-flex xs2 v-if="selected">
                        <v-btn icon dark class="align-content-end" @click="closeDetail">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-flex>
                </v-layout>
                <div v-if="selected" class="text-detalles">
                    <div class="award" v-for="(award, i) in selectedArt.awards" :key="i">
                        <span>{{award.year}}</span> - <span>{{award.categoria}}</span> -  <span>{{award.premio}}</span>
                        <div><b>Resultado: </b>{{award.resultado}}</div>
                        <div>{{award.tipo}}</div>

                    </div>

                </div>
            </div>
        </v-flex>
    </v-layout>
</template>
<script>
    // import SocialSharingNetwork from "vue-social-sharing/src/social-sharing-network";
    // let Highcharts = require('highcharts');
    import {Chart} from 'highcharts-vue'
    import {isMobile} from 'mobile-device-detect';


    export default {
        name: "",
        data: () => ({
            chart: null,
            legend: {},
            movil: isMobile,
            chartOptions: {},
            paneOption: {},
            selected: false,
            selectedArt: {},
            ganadosData: [],
            awards: [],
            nominacionesData: [],
            nombreArtistas: [],
            chartPremios: {},
            texto: 'No existe concurso, competencia o premio exclusivo para este género en Cuba. No todos los artistas son bien vistos y muchas veces el poder político o mediático ha intentado invisibilizarlo. Los Premios Lucas evalúan los videos clips, y con sus categorías de género urbano, artista novel y el premio de la popularidad, ofrece una ventana para mirar al reguetón.' +
                '<br><br>Aquí tuvimos en cuenta premios y nominaciones nacionales y extranjeros, entre ellas los premios Grammy, Billboard y Juventud y solo 11 artistas han recibido algún tipo de reconocimiento.' +
                '<br><br>La mayor cantidad de premios la obtuvo Gente de Zona como el tema Bailando, un súper éxito mundial en 2014. También es curioso que los resultados no incluyen a ningún exponente del reparto, ni siquiera Chocolate que solo tiene dos nominaciones como invitado el Chupi Chupi, lo que pone a esos artistas en desventaja con respecto al resto en esta categoría.'
        }),
        components: {
            // SocialSharingNetwork,
            highcharts: Chart
        },
        props: {
            graficoData: {
                type: [Array, Object]
            },
            viewText: {
                type: Boolean,
                default: true
            }
        },
        // beforeMount() {
        //     this.loadPremios()
        // },
        watch: {
            graficoData(value) {
                this.loadPremios(value);
            }
        },
        methods: {
            closeDetail(){
              this.selected = false
              this.selectedArt = {}
            },
            loadPremios(data) {
                for (let e of data) {
                    this.ganadosData.push(e.ganados)
                    this.nominacionesData.push(e.nominaciones)
                    this.nombreArtistas.push(e.nombre)
                    this.awards[e.nombre] = e.awards
                }
            },
            setChartOptIfMobile() {
                if (isMobile) {
                    this.chartPremios.chart.margin = 30
                    this.chartPremios.chart.marginRight = 45
                    this.chartPremios.chart.height = 540
                    this.chartPremios.chart.pinchType = 'x'
                    this.chartPremios.chart.zoomType = 'x'
                    // this.paneOption = {}
                }
            }
        },
        mounted() {
            let vm = this;
            this.chartPremios = {
                chart: {
                    polar: true,
                    type: 'column',
                    className: 'svg-grafico-premios',
                    height: 560,
                    marginTop: 5,
                    marginRight: 0
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: ''
                },
                legend: {
                    align: 'center',
                    verticalAlign: 'bottom',
                    layout: 'horizontal',
                },
                styledMode: true,
                xAxis: {
                    min: 0,
                    showLastLabel: true,
                    label: {
                        formatter: function () {
                            return this.value
                        }
                    },
                    categories: vm.nombreArtistas,
                    reversedStacks: true,
                    showEmpty: true
                },
                exporting: {
                    enabled: false
                },

                yAxis: {
                    tickmarkPlacement: 'on'
                },

                tooltip: {
                    valueSuffix: ''
                },
                plotOptions: {
                    series: {
                        stacking: 'normal',
                        shadow: false,
                        groupPadding: 0,
                        pointPlacement: 'on',
                        events: {
                            click: function (data) {
                                vm.selectedArt = {'nombre':data.point.category, 'awards': vm.awards[data.point.category]}
                                vm.selected = true

                            }
                        }
                    }
                },
                series: [{
                    name: 'Nominaciones',
                    data: vm.nominacionesData,
                    color: '#e5dc16'
                }, {
                    name: 'Premios ganados',
                    data: vm.ganadosData,
                    color: '#7d0ef2'
                }]
            }
            this.setChartOptIfMobile()
        }
    }
</script>
<style>
    .highcharts-legend-item tspan {
        font-family: "Andada" !important;
        font-size: 12px !important;
        font-weight: normal !important;
        text-transform: uppercase;
    }
</style>
<style lang="scss" scoped>
    .grafico-premios-container {
        max-width: 100vw;
        height: 100vh;
        // padding: 2vw;
        background-color: white
    }

    .back-inactive {
        background: url("/img/menu/toolbar-gradient.png");
        background-size: 100%;
    }

    .cell-data {
        margin-top: 20px;
    }

    .cell-map {
        margin-right: 0px;
        background: white;
    }
    .name-singer {
        font-size: 14px;
        text-transform: uppercase;
        font-family: Andada-Bold;
        border-bottom: 1px solid #FFF;
        margin-bottom: 10px;
        padding-bottom: 10px;
    }

    .cell-map-movil {
        margin-right: 50px;
    }

    .title-leyenda, .title-detalles {
        font-family: Andada-Bold;
        text-transform: uppercase;
        font-size: large;
    }
    .award {
        margin: 10px 5px;
        border-bottom: 1px solid white;
    }
    .full-row {
        background: black;
        background: url('/img/artistas/artistas-textura-bkg.jpg') repeat-y center;
    }

    .genero {
        text-transform: capitalize;
        border-bottom: 1px solid #FFF;
        margin-bottom: 10px;
        padding-bottom: 10px;
    }

    .leyenda-movil {
        margin-top: 0 !important;
    }

    .leyenda, .leyenda-movil, .detalles, .detalles-movil {
        width: 95%;
        background-color: #000000;
        color: white;
        margin-top: 10px;
        padding: 14px;
    }

    .leyenda-movil {
        width: 100%;
    }

    .detalles-movil {
        position: fixed;
        width: 100%;
        z-index: 100;
        top: 0px;
        left: 0px;
        height: unset;
        bottom: 0;
        margin: 0;
        background-color: rgba(0, 0, 0, .9);
    }

    .title-leyenda, .title-detalles {
        font-family: Andada-Bold;
        text-transform: uppercase;
        font-size: large;
    }

    .artist-list {
        cursor: pointer;
        text-transform: uppercase;
        margin-left: 10px;
    }

    .text-leyenda {
        // font-family: "Andada";
        overflow: auto;
        margin: 20px 0px 10px 10px;
        padding: 10px;
        max-height: 350px;
    }

    .text-detalles, .text-detalles-movil {
        margin: 20px 0px 10px 10px;
        font-family: "Andada";
        max-height: 380px;
        overflow: auto;
    }

    .text-detalles-movil {
        max-height: 75vh;
    }

    ::-webkit-scrollbar {
        width: 4px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    @media only screen and (min-device-width: 0px) and (max-device-width: 740px) {
        #chart-premios {
            width: 100vw;
        }

        .grafico-premios-container {
            height: 100%;
        }
    }
</style>
