<template>
  <div class="artistas-component" data-view>
    <ArtistasDesktop
      class="hidden-sm-and-down"
      :headerText="text"
      :graficosTextos="textos"
      :datosArtistas="datosArtistas"
    />
    <ArtistasMobile
      class="hidden-md-and-up"
      :mobileHeaderText="text"
      :graficosTextos="textos"
      :datosArtistas="datosArtistas"
    />
    <v-layout row wrap class="pa-4" v-if="!$store.getters.getViewPerfilArtista">
      <v-flex xs12 sm6 class="canciones-lista-comentarios">
        <ListaComentarios />
      </v-flex>
      <v-flex xs12 sm6 class="pt-4">
        <ComentariosForm />
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
// @scroll="handleScroll()"
import ArtistasDesktop from "../../components/artistas/ArtistasDesktop";
import ArtistasMobile from "../../components/artistas/ArtistasMobile";
import ComentariosForm from "../../components/Comentarios/ComentariosForm";
import ListaComentarios from "../../components/Comentarios/ListaComentarios";
// import ComentariosModal from '../../components/Comentarios/ComentariosModal'
// import artistas from '../../assets/artistasData/artistas.json'
// import artistasgraph from '../../assets/artistasData/artistsgraph.json'
// import artistasPos from '../../assets/artistasData/artistspos.json'
// import premios from '../../assets/artistasData/premios.json'
// import redes from '../../assets/artistasData/redes.json'
// import canciones from '../../assets/songs.json'

export default {
  name: "ArtistasComponent",
  head: {
    title: {
      inner: "Flow y poder",
      separator: " - ",
      complement: "La Realeza"
    },
    meta: [
      { p: "og:title", c: "Flow y poder - La Realeza" },
      {
        p: "og:description",
        c:
          "¿Quiénes son los más populares? o ¿quiénes han estado más tiempo pegados? son preguntas habituales cuando se habla de reguetón cubano. A partir de una lista de 155 artistas y un análisis de varios indicadores empezamos a calcular... Aquí los resultados... y un ganador."
      },
      {
        n: "description",
        c:
          "¿Quiénes son los más populares? o ¿quiénes han estado más tiempo pegados? son preguntas habituales cuando se habla de reguetón cubano. A partir de una lista de 155 artistas y un análisis de varios indicadores empezamos a calcular... Aquí los resultados... y un ganador."
      },
      { p: "og:url", c: "https://reggaeton.eltoque.com/la-realeza" },
      { p: "og:image", c: "https://reggaeton.eltoque.com/img/artistas/portada.jpg" }
    ]
  },
  fetch({ store }) {
    if (store.getters.loadedFullArtistas) return;
    return store.dispatch("fetchArtistasData");
  },
  data() {
    return {
      text:
        "Reyes, príncipes, animales salvajes, entre otros personajes con delirio de grandeza, disputan el trono del reguetón. De 2010 a 2018, 155 artistas individuales y 33 grupos de género urbano “pegaron” más de 600 canciones en Cuba.<br><br>" +
        "Entre la farándula y el reparto, las uniones y las tiraderas, los nuevos grupos y los que logran mantenerse, los que pueden cantar fuera y los que no tienen permiso ni para cantar dentro; entre cadenas de oro, mujeres glamorosas y carros caros, por un lado, y marginalidad, pocos recursos y un lenguaje vulgar, por otro, el reguetón resiste, se fortalece y se transforma. Hay una lucha silenciosa por estar en la cima, que significa ser popular, tener muchas vistas en Youtube y sonar en todas partes.<br><br>" +
        '79 artistas van en la avanzada y en este tiempo han pegado más de una canción. Algunos como el Chacal (118) El Tiger (90) y Chocolate (76) llevan ventaja. Pero, ¿qué más hace falta para ser el dueño de la corona?"',
      relaciones:
        "Para quien está empezando en el mundo del reguetón unirse a uno de los artistas más influyentes puede ser clave para avanzar.<br><br>" +
        "En este gráfico cada punto representa un artista —de manera individual, no por grupos— y están unidos entre sí cuando han cantado al menos una canción juntos. Los colores identifican el tipo de reguetón que más han interpretado: reparto (azul) o reguetón de farándula (fucsia). Un cantante es más influyente según la cantidad de canciones que tenga con otros que también sean importantes.<br><br>" +
        "Es evidente la separación en dos comunidades donde los que más se relacionan entre sí son los exponentes del mismo género y entre ellos tiene subgrupos. En el centro se ubican el Chacal y Chocolate quienes han cantado con 58 y 49 artistas respectivamente, de ambas comunidades. Entre los reparteros son Kamel y Haryson, después de Chocolate, los más importantes. En la farándula siguen al Chacal, El Micha, Osmany García y el Taiger.<br><br>" +
        "En el caso de Alexander (7) y Randy Malcom (10), de Gente de Zona, tienen menor puntuación pues la mayoría de los featuring que han hecho son con músicos extranjeros, 24 de 42 artistas foráneos han cantado con ellos.<br><br>" +
        "En un mundo dominado casi absolutamente por los hombres, también se han unido al reguetón otros 51 artistas cubanos que interpretan otros géneros. Conectarse al reguetón es una buena estrategia para pegar.",
      posicion:
        "Mientras más famosa es una canción, más reconocido es el artista. Por eso, para saber cuándo una canción es popular los especialistas y los medios de comunicación elaboran listas de éxitos, con frecuencia semanal, mensual o anual; y sobre todo miden por cuánto tiempo están en el top.<br><br>" +
        "Sin embargo, este método se puede parcializar e incluir o excluir a un artista, según la opinión de quien elabore la lista y sus gustos personales e interés. Entonces, preguntar directamente a las personas es importante.<br><br>" +
        "Entre los 10 primeros de las listas de éxitos y de las encuestas que nosotros aplicamos, hay diferencias. En las listas el reparto casi no se incluye y en las encuestas sí. Alexander y Randy Malcom tiene las primeras posiciones en las listas y Chocolate en las encuestas. Aunque en general la mejor posición entre todos los indicadores la tiene El Chacal, quien ha estado en las listas mensuales y listas 81 veces con 33 canciones y, además, salió de segundo en las encuestas.",
      redes:
        "Cada año es distinto, el acceso a Internet en Cuba ha aumentado progresivamente y cada vez más artistas apuestan por estar en el escenario virtual, lugar al que van llegando también los usuarios. Youtube es el mejor ambiente para consumir los videos clip.<br><br>" +
        "Con datos obtenidos en mayo de 2019, analizamos la cantidad de vistas, likes, dislikes y comentarios por canción. Y miramos al artista, según la puntuación de cada una de sus canciones.<br><br>" +
        "Mientras más actual es la canción más posibilidades tiene de aumentar sus estadísticas, aunque algunas canciones que llevan más tiempo van acumulando estadísticas de a poco.",
      premios:
        "No existe concurso, competencia o premio exclusivo para este género en Cuba. No todos los artistas son bien vistos y muchas veces el poder político o mediático ha intentado invisibilizarlo. Los Premios Lucas evalúan los videos clips, y con sus categorías de género urbano, artista novel y el premio de la popularidad, ofrece una ventana para mirar al reguetón.<br><br>" +
        "Aquí tuvimos en cuenta premios y nominaciones nacionales y extranjeros, entre ellas los premios Grammy, Billboard y Juventud y solo 12 artistas han recibido algún tipo de reconocimiento.<br><br>" +
        "La mayor cantidad de premios la obtuvo Gente de Zona como el tema Bailando, un súper éxito mundial en 2014. También es curioso que los resultados no incluyen a ningún exponente del reparto, ni siquiera Chocolate, lo que pone a esos artistas en desventaja con respecto al resto en esta categoría.",
      // listadoArtistas: [],
      // topPosicion: [],
      // topRedes: [],
      // datosGrafo: [],
      // datosPremios: [],
      // datosPosicion: [],
      // datosRedes: {},
      // topFiveGrafo: [],
      // topFivePosicion: [],
      // topFiveRedes: [],
      textos: {},
      datosArtistas: {},
      // cancionesPopulares: []
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$store.commit("setShowToolbarTitle", true);
      vm.$store.commit("setShowComponentsInFooter", true);
      vm.$store.commit("setViewCancionesBox", true);
      vm.$store.commit("setViewArtistasBox", false);
      vm.$store.commit("setViewLasBalasBox", true);
      vm.$store.commit("setViewJuegoBox", true);
      next();
    });
  },
  components: {
    ComentariosForm,
    ListaComentarios,
    // ComentariosModal,
    ArtistasDesktop,
    ArtistasMobile
  },
  mounted() {
    this.loadData();
    this.textos = {
      grafo: this.relaciones,
      posicion: this.posicion,
      redes: this.redes,
      premios: this.premios
    };
  },
  // computed: {
  //   datosArtistas() {
  //     return this.$store.getters.datosArtistas;
  //   },
  // },
  methods: {
    async loadData() {
      if (!this.$store.getters.loadedFullArtistas) {
        await this.$store.dispatch("fetchArtistasData");
      }

      // await this.getArtistas();
      // this.getTopFivePosicion();
      // this.getTopFiveRedes();
      // await this.getDatosGrafo();
      // this.getTopFiveGrafo();
      // const { default: artistasPos } = await import(
      //   "../../assets/artistasData/artistspos.json"
      // );
      // this.datosPosicion = artistasPos;
      // const { default: redes } = await import(
      //   "../../assets/artistasData/redes.json"
      // );
      // this.datosRedes = redes;
      // this.datosArtistas = {
      //   artistas: this.listadoArtistas,
      //   grafo: { topFive: this.topFiveGrafo, general: this.datosGrafo },
      //   posicion: {
      //     topFive: this.topFivePosicion,
      //     general: this.datosPosicion
      //   },
      //   redes: { topFive: this.topFiveRedes, general: this.datosRedes },
      //   premios: await this.getDatosPremios()
      // };
      this.datosArtistas = this.$store.getters.datosArtistas;
    },
    // async getArtistas() {
    //   let index = 0;
    //   const { default: artistas } = await import(
    //     "../../assets/artistasData/artistas.json"
    //   );
    //   const { default: canciones } = await import(
    //     /* webpackChunkName: "data-songs" */ "../../assets/songs.json"
    //   );
    //   artistas.filter(artista => {
    //     if (
    //       artista !== undefined &&
    //       artista.tipo === "regular" &&
    //       (typeof artista.perfil === "object" &&
    //         artista.perfil.constructor === Object)
    //     ) {
    //       this.cancionesPopulares = [];
    //       artista.songs.find(id => {
    //         canciones.find(cancion => {
    //           if (id === cancion.id) {
    //             this.cancionesPopulares.push(cancion.songtitle);
    //           }
    //         });
    //       });
    //       this.listadoArtistas.push({
    //         nombre: artista.nombre,
    //         mainFoto:
    //           "/img/artistas/artistas-inside/" + artista.nombre + ".jpg",
    //         perfilFoto:
    //           "/img/artistas/avatar_artistas/" + artista.nombre + ".jpg",
    //         perfil: artista.perfil,
    //         cancionesPopulares: this.cancionesPopulares,
    //         index: index,
    //         id: artista.id
    //       });
    //       index++;
    //     }
    //   });
    // },
    // getTopFivePosicion() {
    //   this.listadoArtistas.find(a => {
    //     if (
    //       a.nombre === "El Chacal" ||
    //       a.nombre === "Chocolate" ||
    //       a.nombre === "El Taiger" ||
    //       a.nombre === "El Dany" ||
    //       a.nombre === "Yomil"
    //     ) {
    //       this.topFivePosicion.push(a);
    //     }
    //   });
    // },
    // getTopFiveRedes() {
    //   this.listadoArtistas.find(a => {
    //     if (
    //       a.nombre === "Alexander" ||
    //       a.nombre === "El Chacal" ||
    //       a.nombre === "Randy Malcom" ||
    //       a.nombre === "Jacob Forever" ||
    //       a.nombre === "Osmani García"
    //     ) {
    //       this.topFiveRedes.push(a);
    //     }
    //   });
    // },
    // async getDatosGrafo() {
    //   const { default: artistasgraph } = await import(
    //     "../../assets/artistasData/artistsgraph.json"
    //   );
    //   this.listadoArtistas.find(a => {
    //     artistasgraph.find(artista => {
    //       if (artista.nombre === a.nombre) {
    //         artista.mainFoto = a.mainFoto;
    //         artista.perfilFoto = a.perfilFoto;
    //         artista.perfil = a.perfil;
    //         artista.cancionesPopulares = a.cancionesPopulares;
    //       }
    //     });
    //   });
    //   this.sortData(artistasgraph, "betweeness");
    //   this.datosGrafo = artistasgraph;
    // },
    // async getDatosPremios() {
    //   const { default: premios } = await import(
    //     "../../assets/artistasData/premios.json"
    //   );
    //   this.sortData(premios, "ganados");
    //   this.listadoArtistas.find(a => {
    //     premios.find(artista => {
    //       if (artista.nombre === a.nombre) {
    //         artista.mainFoto = a.mainFoto;
    //         artista.perfilFoto = a.perfilFoto;
    //         artista.perfil = a.perfil;
    //         artista.cancionesPopulares = a.cancionesPopulares;
    //       }
    //     });
    //   });
    //   return premios;
    // },

    // sortData(data, key) {
    //   data.sort((a, b) => {
    //     return a[key] < b[key] ? 1 : a[key] > b[key] ? -1 : 0;
    //   });
    // },
    // getTopFiveGrafo() {
    //   this.topFiveGrafo = this.datosGrafo.slice(0, 5);
    // }
  }
};
</script>

<style scoped>
/* @media only screen and (min-device-width: 1024px) and (max-device-width: 2048px) {
      .artistas-mobile {
        display: none;
      }
      .artistas-desktop {
        display: block;
      }
    }

    @media only screen and (min-device-width: 0px) and (max-device-width: 740px) {
      .artistas-mobile {
        display: block;
      }
      .artistas-desktop {
        display: none;
      }
    } */
</style>

<!--<transition name="slide-fade">-->
<!--<ArtistaInside v-if="display_artista_info" id="artistas-inside-wrapper" class="artistas-inside-wrapper"-->
<!--/>-->
<!--</transition>-->
